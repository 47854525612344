import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom"; // Import useParams

import AppMetaDataContext from "../context/AppMetaDataContext";

import socialImage from "../lingo-champion-social-sharing.png";

import LearnDuolingoVocabularyMainCTA from "../Components/LearnDuolingoVocabularyMainCTA";
import Benefits from "../Components/Benefits";
import BackedByScience from "../Components/BackedByScience";
import Customize from "../Components/Customize";
import FounderQuote from "../Components/FounderQuote";
import DownloadExtension from "../Components/DownloadExtension";
import WeDontTrainLinguists from "../Components/WeDontTrainLinguists";
import Explanations from "../Components/Explanations";
import Videos from "../Components/Videos";
import BrowserExtension from "../Components/BrowserExtension";
import GenerateContentWithAI from "../Components/GenerateContentWithAI";
import StatisticsSection from "../Components/StatisticsSection";
import Audio from "../Components/Audio";
import FlashcardsFeatureSection from "../Components/FlashcardsFeatureSection";
import UseChat from "../Components/UseChat";
import StudySongs from "../Components/StudySongs";
function LanguageCourse() {
  let { language } = useParams(); // Extract the language parameter
  language = capitalizeFirstLetter(language);

  const metaData = React.useContext(AppMetaDataContext);
  const helmetMetaContent = `Learn ${language} online by reading and watching native speaker content and have AI explain words to you. Filter and simplify content that matches your level.`;
  return (
    <>
      <Helmet>
        <title>Learn {language} online through native speaker content</title>
        <meta
          name="description"
          key="description"
          content={helmetMetaContent}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "3% 3%" }}>
        <LearnDuolingoVocabularyMainCTA language={language} />
        {/* <HowItWorks /> */}
        <WeDontTrainLinguists />
        <Customize />
        {/* <BuiltForComprehensibleInput /> */}
        <BackedByScience />
        <Explanations />
        <UseChat />
        <Audio />
        <FlashcardsFeatureSection />
        <Videos />
        <StudySongs />
        <GenerateContentWithAI />
        <StatisticsSection />
        <Benefits />
        <BrowserExtension />
        <FounderQuote language={language} />
        <DownloadExtension language={language} />
      </Container>
    </>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default LanguageCourse;
