import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import AuthContext from "../context/AuthContext"; // Assuming this is the correct path
import { createPopper } from "@popperjs/core";
import SpeechButton from "./SpeechButton";
import Button from "react-bootstrap/Button";
import "./QuickTranslationTooltip.css";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const QuickTranslationTooltip = ({
  selectedWord,
  setSelectedWord,
  setWordForFullLookup,
  addUnknownWordToKnownWords,
  updateKnownWordInKnownWords,
  speakOnRender,
}) => {
  const context = useContext(AuthContext);
  const [fetchingQuickTranslation, setFetchingQuickTranslation] =
    useState(false);
  const [popperInstance, setPopperInstance] = useState(null);
  const [theMostLikelyTranslation, setTheMostLikelyTranslation] =
    useState(null);

  useEffect(() => {
    console.log(
      "Quick translation tooltip detected word change:",
      selectedWord
    );

    // Clear previous translation and fetching state when selectedWord changes
    setTheMostLikelyTranslation(null);
    setFetchingQuickTranslation(false);

    // Clear the tooltip translation content
    const tooltipTranslation = document.querySelector("#tooltip #translation");
    if (tooltipTranslation) {
      tooltipTranslation.textContent = "";
    }

    if (selectedWord && selectedWord.word && selectedWord.word.trim() !== "") {
      console.log(
        "Should show quick translation tooltip for the word: ",
        selectedWord
      );
      handlePopperTooltip(selectedWord);
    } else {
      // hide the tooltip
      hideTooltip();
    }
  }, [selectedWord]);

  function handlePopperTooltip(selectedWord) {
    console.log(
      "Checking if there's a highlighted word that corresponds to: ",
      selectedWord
    );
    const firstHighlightedElement = document.querySelector(".highlighted");
    if (firstHighlightedElement) {
      console.log("First highlighted element found:", firstHighlightedElement);
      // make tooltip visible
      document.querySelector("#tooltip").style.display = "block";
    } else {
      // REVIEW: not sure what to do here exactly
      console.log("No highlighted element found.");
    }
    const tooltip = document.querySelector("#tooltip");

    // Pass the button, the tooltip, and some options, and Popper will do the
    // magic positioning for you:
    if (firstHighlightedElement && tooltip) {
      const instance = createPopper(firstHighlightedElement, tooltip, {
        placement: "top",
        modifiers: [
          {
            name: "arrow",
            options: {
              element: document.querySelector("#arrow"),
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "viewport", // or 'window'
            },
          },
        ],
      });
      setPopperInstance(instance);

      // Update the popper instance after setting the content
      if (!selectedWord.translation) {
        fetchQuickTranslation(selectedWord, instance);
      } else {
        document.querySelector("#translation").textContent =
          selectedWord.translation;
        instance.update();
        // set the tooltip visible
        tooltip.style.display = "block";
      }
    }
  }

  function hideTooltip() {
    document.getElementById("tooltip").style.display = "none";
  }

  // useEffect for fetchingQuickTranslation
  useEffect(() => {
    console.log("fetchingQuickTranslation changed: ", fetchingQuickTranslation);
    if (fetchingQuickTranslation && popperInstance) {
      popperInstance.update();
    }
  }, [fetchingQuickTranslation, popperInstance]);

  useEffect(() => {
    if (!fetchingQuickTranslation && popperInstance) {
      popperInstance.update();
    }
  }, [fetchingQuickTranslation, popperInstance]);

  async function fetchQuickTranslation(selectedWord, popperInstance) {
    console.log("Fetching quick translation for word: ", selectedWord);

    // Check if word_lookups_count is 5 or more
    if (context.plan_word_lookups_count_limit_reached) {
      console.log(
        "Word lookups count is 500 or more, not fetching translation."
      );
      setFetchingQuickTranslation(false);
      return;
    }

    const selectedLanguagePair = context.language_pairs.find(
      (pair) => pair.is_selected
    );
    const tooltipTranslation = document.querySelector("#tooltip #translation");
    tooltipTranslation.textContent = ""; // Clear previous translation
    setFetchingQuickTranslation(true);
    popperInstance.update();

    try {
      const response = await axios.post("/api/translate-word-quickly-with-ai", {
        word: selectedWord.word,
        context: selectedWord.sentence,
        language_learning: selectedLanguagePair.language_learning.name,
        language_base: selectedLanguagePair.language_base.name,
      });
      setFetchingQuickTranslation(false);
      console.log(
        "RESPONSE FROM TRANSLATE ONE WORD QUICKLY WITH AI: ",
        response.data
      );
      console.log("--- ORIGINAL WORD: ", response.data.original);
      console.log("--- LOCAL SELECTED WORD: ", selectedWord.word);
      // get the tooltip element
      console.log("POPPER: ", popperInstance);
      // set the translation to the tooltip
      // TODO: should actually rely only on the state and not set it manually
      tooltipTranslation.textContent =
        response.data.translation.the_most_likely_translation;
      setTheMostLikelyTranslation(
        response.data.translation.the_most_likely_translation
      );
      console.log("Set theMostLikelyTranslation:", response.data.translation.the_most_likely_translation);
      context.updateWordLookupsCount(response.data.word_lookups_count);


      // Update the popper instance after setting the content
      popperInstance.update();
    } catch (error) {
      setFetchingQuickTranslation(false);
      console.error("Error fetching quick translation:", error);
    }
  }

  function createFragments(element) {
    const numPieces = 30; // Number of pieces
    const rect = element.getBoundingClientRect();

    for (let i = 0; i < numPieces; i++) {
      const piece = document.createElement("div");
      piece.classList.add("fragment");
      piece.style.left = `${rect.left + rect.width / 2}px`; // Center based on screen position
      piece.style.top = `${rect.top + rect.height / 2}px`; // Center based on screen position
      piece.style.setProperty("--x", (Math.random() - 0.5) * 200); // Random x direction
      piece.style.setProperty("--y", (Math.random() - 0.5) * 200); // Random y direction

      document.body.appendChild(piece); // Append to body to prevent layout shift

      setTimeout(() => {
        piece.remove();
      }, 1500); // Match with animation duration
    }
  }

  function addExplosionEffect() {
    console.log("Adding explosion effect");
    // get all highlighted elements
    const highlightedElements = document.querySelectorAll(".highlighted");
    if (highlightedElements.length > 0) {
      // get middle element index
      const middleIndex = Math.floor(highlightedElements.length / 2);
      // create fragments from middle element
      createFragments(highlightedElements[middleIndex]);

      // remove classes from all highlighted elements
      highlightedElements.forEach((element) => {
        element.classList.remove("highlighted");
        element.classList.remove("unknown");
      });
    }
  }

  return (
    <div id="tooltip" role="tooltip">
      {console.log("Rendering QuickTranslationTooltip: ", selectedWord)}

      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div id="translationSpinner">
            {fetchingQuickTranslation && (
              <Spinner animation="grow" size="sm" className="me-2" />
            )}
          </div>
          <SpeechButton
            word={selectedWord?.word}
            speakOnRender={speakOnRender}
          />
        </div>

        <div id="translation">I'm a tooltip</div>
        {!fetchingQuickTranslation && (
          <>
            {context.plan_word_lookups_count_limit_reached &&
              !theMostLikelyTranslation &&
              !selectedWord?.translation && (
                <Link
                  to="/pricing"
                  className="alert alert-warning alert-pill p-1 m-1"
                  role="alert"
                >
                  Limit reached
                </Link>
              )}
            {!selectedWord?.known &&
              !context.plan_word_lookups_count_limit_reached && (
                <Button
                  variant="outline-light"
                  size="sm"
                  style={{ padding: "0.2rem 0.4rem", fontSize: "0.75rem" }}
                  className="ms-3 tooltip-action-button"
                  onClick={() => {
                    hideTooltip();
                    addUnknownWordToKnownWords(
                      selectedWord.word,
                      theMostLikelyTranslation,
                      0
                    );
                    addExplosionEffect();
                  }}
                >
                  Save
                </Button>
              )}
            {selectedWord?.known &&
              selectedWord?.translation === undefined &&
              !context.plan_word_lookups_count_limit_reached && (
                <Button
                  variant="outline-light"
                  size="sm"
                  style={{ padding: "0.2rem 0.4rem", fontSize: "0.75rem" }}
                  className="ms-3 tooltip-action-button"
                  onClick={() => {
                    hideTooltip();
                    console.log("updating known word in known words: ", selectedWord, theMostLikelyTranslation, 0);
                    updateKnownWordInKnownWords(
                      selectedWord,
                      theMostLikelyTranslation,
                      0
                    );
                    addExplosionEffect();
                  }}
                >
                  Save
                </Button>
              )}
            <Button
              variant="outline-light"
              size="sm"
              className="ms-2 tooltip-action-button"
              style={{ padding: "0.2rem 0.4rem", fontSize: "0.75rem" }}
              onClick={() => {
                setWordForFullLookup(selectedWord);
                hideTooltip();
              }}
            >
              Explain
            </Button>
          </>
        )}
      </div>
      <div id="arrow" data-popper-arrow></div>
    </div>
  );
};

export default QuickTranslationTooltip;
