import React, { useEffect } from "react";
import { Trophy, TrophyFill } from "react-bootstrap-icons";
import AuthContext from "../context/AuthContext";

const StreakNumber = () => {
  const context = React.useContext(AuthContext);

  // useEffect to fetch statistics
  useEffect(() => {
    context.fetchStatistics();
  }, []);

  return (
    <div>
      {context.statistics?.missedCurrentDay ? (
        <div className="d-flex align-items-center">
          <Trophy color="darkgray" size="15" className="me-2 my-0" />
          <span
            style={{
              color: "darkgray",
              marginBottom: "1px",
              fontWeight: "bold",
            }}
          >
            {context.statistics?.streakCount}
          </span>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <TrophyFill color="yellow" size="15" className="me-2 my-0" />
          <span
            style={{
              color: "yellow",
              marginBottom: "1px",
              fontWeight: "bold",
              // textShadow: `-1px -1px 0 orange, 1px -1px 0 orange, -1px  1px 0 orange, 1px  1px 0 orange`,
            }}
          >
            {context.statistics?.streakCount}
          </span>
        </div>
      )}
    </div>
  );
};

export default StreakNumber;
