import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form, Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ProgressBar } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import {
  ThreeDots,
  InfoCircle,
  Bug,
  BoxArrowUpRight,
} from "react-bootstrap-icons";
// import playlistModal
import PlaylistModal from "../Components/PlaylistModal";
import { GeneratingAudioModal } from "../Components/AudioModals.js";
import QuickTranslationTooltip from "../Components/QuickTranslationTooltip.js";

// import bootstrap react icons
import {
  Magic,
  Robot,
  Headphones,
  ArrowClockwise,
} from "react-bootstrap-icons";

import AuthContext from "../context/AuthContext";

// import articleData from "./Reader-example.json";
// import knownWords from "./Reader-known-words.json";
import { useLocation } from "react-router-dom";

import {
  splitIntoSentences,
  splitIntoTokens,
  checkTranslationPanelProp,
  escapeHTML,
  sendBugReport,
  openSupportCenter,
} from "../utils/ReaderUtils.js";
import ReaderFinishedPage from "./ReaderFinishedPage";

import { useNavigate } from "react-router-dom";

import axios from "axios";

// import css for Reader
import "./Reader.css";
import TranslationPanel from "./TranslationPanel";
import { checkIfWordIsKnown } from "../utils/ReaderUtils";

let isSelecting = false;

// BUG: if you finish the article and navigate back to Dashboard and read the same article, the words are kept in memory and the words are not marked from the beginning
let tokens = [];
/* 
  {
    _id: "id",
    date_added: "date",
    word: "word",
    known: true,
    is_title: true,
    is_last_title_word: true,
    is_not_a_word: true,
    is_number: false,
    translation: "translation",
    strength: 2,

    is_number: true,
    is_punctuation: true,
  }
*/
function App() {
  const context = React.useContext(AuthContext);

  // const [tokens, setTokens] = useState([]);
  const [viewportHeight, setViewportHeight] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [startingWordIndex, setStartingWordIndex] = useState(0);
  const [endingWordIndex, setEndingWordIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [articlePages, setArticlePages] = useState([]); // [{start: 0, end: 10}, {start: 11, end: 20}
  const [currentArticlePage, setCurrentArticlePage] = useState(0); // 0
  const [showDebuggingInfo, setShowDebuggingInfo] = useState(false);
  const [knownWordsArray, setKnownWordsArray] = useState(null);
  const [knownWordsAtTheStart, setKnownWordsAtTheStart] = useState([]);
  const [showFinishedPage, setShowFinishedPage] = useState(false);
  const [articleIsInserted, setArticleIsInserted] = useState(false);
  const [simplificationIsProcessing, setSimplificationIsProcessing] =
    useState(false);
  const [simplificationMessage, setSimplificationMessage] = useState(false);
  const [simplificationError, setSimplificationError] = useState(false);
  const [generateAudioIsProcessing, setGenerateAudioIsProcessing] =
    useState(false);
  const [showAudioIsProcessingModal, setShowAudioIsProcessingModal] =
    useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [popupWarningMessage, setPopupWarningMessage] = useState(false);
  const [showPlaylistSelectionModal, setShowPlaylistSelectionModal] =
    useState(false);

  // State variables to manage the text content
  const [selectedWordOriginalTextState, setSelectedWordOriginalTextState] =
    useState({});
  const [wordForQuickLookup, setWordForQuickLookup] = useState(null);
  const [wordForFullLookup, setWordForFullLookup] = useState(null);

  const [debuggingLog, setDebuggingLog] = useState([]);

  console.log("setting quicklookup to: ", context.settings);
  const quickLookupRef = useRef(context.settings?.quick_lookup_in_reader);
  const expandAIExplanationBoxRef = useRef(
    context.settings?.expand_ai_explanation_box
  );

  let navigate = useNavigate();

  // Prevents default context menu on right-click or long-press (this should include mobile text selection)
  // NOTE: it does not work on Android because onTouchEnd somehow gets triggered differently (after closing the default context menu - preventDefault doesn't work)
  const handleContextMenu = (e) => {
    e.preventDefault();

    // Check if the device is Android
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isAndroid) {
      // Check if the user is currently touching the screen
      if (!e.touches || e.touches.length === 0) {
        // BUG: the following hack works but not for Thai because there are no spaces there
        const selection = window.getSelection().toString().trim();
        // if the selection contains multiple words, trigger the handleTextSelection function
        // Otherwise this event gets triggered already when long pressing on the screen
        if (selection) {
          const words = selection
            .split(/\s+/)
            .filter((word) => word.length > 0);
          if (words.length > 1) {
            handleTextSelection();
          }
        }
      }
    }
  };

  // NOTE: this is purely bc tokens is declared outside the component and the values would persist even when the component gets unmounted (moving betw library and reader)
  useEffect(() => {
    if (tokens.length > 0) tokens = [];
  }, []);

  // useEffect for articleIsInserted
  useEffect(() => {
    console.log("articleIsInserted changed: ", articleIsInserted);
    if (!articleIsInserted) {
      // set the content of the textContentWords div to empty
      document.getElementById("textContentWords").innerHTML =
        "Loading the contents..";
    }
  }, [articleIsInserted]);

  // Function to process a text fragment for phrases and tokens
  function processFragment(fragment, sentence) {
    let phrases = knownWordsArray.filter(
      (obj) => obj && obj.word && obj.word.includes(" ")
    );
    let lowerCaseFragment = fragment.toLowerCase();
    let phraseMatches = [];

    // Find all matches first
    phrases.forEach((phrase) => {
      let lowerCasePhrase = phrase.word.toLowerCase();
      let phraseIndex = lowerCaseFragment.indexOf(lowerCasePhrase);
      while (phraseIndex !== -1) {
        phraseMatches.push({
          index: phraseIndex,
          length: phrase.word.length,
          phrase: phrase.word,
        });
        phraseIndex = lowerCaseFragment.indexOf(
          lowerCasePhrase,
          phraseIndex + 1
        );
      }
    });

    // Sort the matches by their start index; in case of a tie, longer phrases first
    phraseMatches.sort((a, b) => a.index - b.index || b.length - a.length);

    let processedFragments = [];
    let currentIndex = 0;

    // Process the matches in order and handle overlaps
    phraseMatches.forEach((match) => {
      if (match.index >= currentIndex) {
        if (currentIndex < match.index) {
          processedFragments.push(
            ...splitIntoTokens(
              fragment.substring(currentIndex, match.index),
              sentence
            )
          );
        }
        processedFragments.push({
          token: fragment.substring(match.index, match.index + match.length),
          sentence,
        });
        currentIndex = match.index + match.length;
      }
    });

    // Process any remaining text
    if (currentIndex < fragment.length) {
      processedFragments.push(
        ...splitIntoTokens(fragment.substring(currentIndex), sentence)
      );
    }

    return processedFragments;
  }

  // Function to split text by URLs and process each fragment
  function splitByURLsAndProcess(sentence) {
    const urlRegex = /https?:\/\/[^\s]+/g;
    let splitText = sentence.split(urlRegex);
    let urlMatches = sentence.match(urlRegex) || [];

    let finalTokens = [];
    splitText.forEach((fragment, index) => {
      finalTokens.push(...processFragment(fragment, sentence));
      if (urlMatches[index]) {
        finalTokens.push({ token: urlMatches[index], sentence });
      }
    });

    return finalTokens;
  }

  // Function to process the entire text
  function processText(text) {
    console.log("processText: Processing text:", text); // Debugging statement
    let sentences = splitIntoSentences(text);
    console.log("processText: Sentences found:", sentences); // Debugging statement

    if (sentences.length === 0) {
      console.log("processText: No sentences to process.");
      return [];
    }

    return sentences.flatMap((sentence) => splitByURLsAndProcess(sentence));
  }

  // fetch known words from the backend
  useEffect(() => {
    const fetchAndSetKnownWords = async () => {
      try {
        await context.fetchKnownWords();
        setKnownWordsAtTheStart(context.known_words || []);
      } catch (error) {
        console.error("Error fetching known words:", error);
      }
    };

    fetchAndSetKnownWords();
  }, []);

  // create currentArticlePage useEffect
  useEffect(() => {
    console.log("currentArticlePage changed: ", currentArticlePage);
  }, [currentArticlePage]);

  // create useEffect for SelectedWordOriginalTextState
  useEffect(() => {
    console.log(
      "SelectedWordOriginalTextState changed: ",
      selectedWordOriginalTextState
    );
    // decide whether to use quick lookup or full lookup
    if (
      selectedWordOriginalTextState?.word &&
      selectedWordOriginalTextState.word.trim() !== ""
    ) {
      if (quickLookupRef.current) {
        console.log(
          "Should use quick lookup for the word: ",
          selectedWordOriginalTextState
        );
        setWordForQuickLookup(selectedWordOriginalTextState);
      } else {
        console.log(
          "Should use full lookup for the word: ",
          selectedWordOriginalTextState
        );
        setWordForFullLookup(selectedWordOriginalTextState);
      }
    }
    // REVIEW: there probably should be a for cleanup
  }, [selectedWordOriginalTextState]);

  const [currentArticle, setCurrentArticle] = useState(null);

  let location = useLocation();
  // Extract collectionId from URL parameters
  const queryParams = new URLSearchParams(location.search);
  const collectionId = queryParams.get("collectionId");

  // fetch the article data from the backend and tokenize it
  useEffect(() => {
    console.log("New location: ", location.pathname);
    const articleId = location.pathname.split("/")[2];
    console.log("Get the article for articleId:", articleId);
    axios
      .get(`/api/articles/${articleId}`)
      .then((response) => {
        if (response.data.tokens)
          response.data.article.tokens = response.data.tokens;
        setCurrentArticle(response.data.article); // Assuming your API returns the article data
        console.log("Got the article from backend: ", response.data.article);
        // console.log("Received article data:", articleData);
        addArticleToUserLibrary(response.data.article._id);
        if (response.data.article?.audio_url) {
          setAudioUrl(response.data.article.audio_url);
        }
      })
      .catch((error) => {
        console.error("Error fetching article:", error);
        // Handle the error appropriately
      });
  }, [location]);

  // useEffect for knownWordsArray and article
  useEffect(() => {
    if (!currentArticle || knownWordsArray === null) {
      console.log(
        "currentArticle or knownWordsArray is empty. Not going to tokenize the article yet or calculate pages."
      );
      return;
    }
    // if (articleIsInserted) {
    //   console.log(
    //     "Article is already inserted. Not going to tokenize the article again or calculate pages."
    //   );
    //   return;
    // }
    // Update your state or perform any necessary operations with the article data
    const articleBody = currentArticle.body;
    const articleTitle = currentArticle.title;

    if (currentArticle.tokens) {
      console.log("Tokens already exist for the article.");
      // make a deep copy of the currentArticle.tokens
      let currentArticleTokens = JSON.parse(
        JSON.stringify(currentArticle.tokens)
      );
      console.log("Current article tokens 1: ", currentArticleTokens);
      // currentArticleTokens.title.words  - loop through the array and add .sentence property to each .word
      function transformWordsArray(array) {
        return array.map((item) => {
          return {
            ...item, // Keep the original sentence
            words: item.words.map((word) => ({
              word: word, // Each word becomes an object
              sentence: item.sentence, // Retain the original sentence
            })),
          };
        });
      }
      function transformTitleObject(titleObj) {
        return {
          ...titleObj, // Keep the original sentence
          words: titleObj.words.map((word) => ({
            word: word, // Each word becomes an object
            sentence: titleObj.sentence, // Retain the original sentence
          })),
        };
      }

      currentArticleTokens.body = transformWordsArray(
        currentArticleTokens.body
      );
      currentArticleTokens.title = transformTitleObject(
        currentArticleTokens.title
      );

      // loop through currentArticleTokens.body array and merge the words into one array
      let finalBodyWordTokens = currentArticleTokens.body.reduce(
        (acc, item) => {
          return acc.concat(item.words);
        },
        []
      );

      let finalTitleWordTokens = currentArticleTokens.title.words;

      // currentArticleTokens.body.words - each word should have the sentence property which equals the sentence
      console.log("current article tokens: ", currentArticleTokens);
      // TODO: need to take care of the title as well
      console.log(
        "final current article title tokens: ",
        currentArticleTokens.title
      );
      console.log("final current article title tokens: ", finalTitleWordTokens);
      console.log("final current article body tokens: ", finalBodyWordTokens);
      let titleTokensToMerge = createWords(
        finalTitleWordTokens,
        knownWordsArray,
        "title"
      );
      let bodyTokensToMerge = createWords(
        finalBodyWordTokens,
        knownWordsArray,
        "body"
      );
      tokens = titleTokensToMerge.concat(bodyTokensToMerge);
      // create words from the tokens
      // let titleTokens = createWords(currentArticleTokens.title, knownWordsArray, "title");
      // let bodyTokens = createWords(currentArticleTokens.body, knownWordsArray, "body");
    } else {
      console.log("Tokens do not exist for the article.");
      let finalWords = processText(articleBody, knownWordsArray);
      let finalArticleTitle = processText(articleTitle, knownWordsArray);

      // Processing the article title
      // const finalArticleTitle = articleTitle
      //   .split(/(\s+|\p{P}+|\p{L}+(?:\p{M}*\p{N}*)*)/gu)
      //   .filter((token) => token !== "")
      //   .map((token) => ({ token, sentence: articleTitle }));

      console.log("FINALARTICLETITLE: ", finalArticleTitle);
      console.log("FINALWORDS: ", finalWords);

      let titleTokens = createWords(
        finalArticleTitle,
        knownWordsArray,
        "title"
      );
      let bodyTokens = createWords(finalWords, knownWordsArray, "body");
      tokens = titleTokens.concat(bodyTokens);
    }

    console.log("TOKENS: ", tokens);
    if (!articleIsInserted) {
      let lastLearnedWordIndex = 0;
      calculatePages(lastLearnedWordIndex);
      setArticleIsInserted(true);
    }

    calculatePages(startingWordIndex);

    if (knownWordsArray === null) return;
    console.log("knownWordsArray changed: ", knownWordsArray);
    // REVIEW: there is probably no need to update the words because I'm now rekotenizing the article every time the knownWordsArray changes anyway
    console.log(
      "Calling the refreshPage function with currentArticlePage: ",
      currentArticlePage
    );
    // to avoid refreshing the page when the knownWordsArray is set to the initial value containing words
    if (articlePages.length !== 0 && showFinishedPage === false) {
      refreshPage(currentArticlePage);
    }
  }, [knownWordsArray, currentArticle]);

  // create useEffect for knownWordsArray
  useEffect(() => {
    return;
  }, [knownWordsArray]);

  useEffect(() => {
    console.log("Known words array changed in the context.");
    // set the knownWordsArray state variable to the new value
    setKnownWordsArray(context.known_words || []);
  }, [context.known_words]);

  // function toggleFullScreen() {
  //   setIsFullScreen(!isFullScreen);
  // }

  async function addArticleToUserLibrary(
    articleId,
    percentComplete = 0,
    wordsRead = 0,
  ) {
    // get the selected language pair ID from the context
    let languagePairId;
    if (context.language_pairs) {
      for (const pair of context.language_pairs) {
        console.log("Selected language pair (in Reader): ", pair);
        if (pair.is_selected) {
          languagePairId = pair._id;
          break;
        }
      }
    }
    console.log("Language pair ID is (in Reader): ", languagePairId);

    try {
      console.log("Trying to add article to user library.");
      const response = await axios.post("/api/user/add-article-to-library", {
        article_id: articleId,
        language_pair_id: languagePairId,
        language_learning_code: context.getSelectedLanguagePair().language_learning.code,
        language_base_code: context.getSelectedLanguagePair().language_base.code,
        collection_id: collectionId, // Include collectionId in the request
        percent_complete: percentComplete,
        words_read: wordsRead,
      });
      console.log("Response from adding article to user library: ", response);
    } catch (error) {
      console.error(
        "There was a problem with adding the article to the user library:",
        error
      );
    }
  }

  // BUG: swiping on mobile doesn't work properly - it's impossible to select text without swiping
  const [startX, setStartX] = useState(null);

  const handleTouchStart = (e) => {
    // e.stopPropagation();
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    // e.stopPropagation();
    // if a selection was made, don't count it as a swipe
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return; // Exit function if text is selected
    }

    if (!startX) {
      return;
    }
    const xDiff = startX - e.touches[0].clientX;

    if (Math.abs(xDiff) > 50) {
      // Threshold for minimal swipe distance
      if (xDiff > 0) {
        getNextPage();
      } else {
        getPreviousPage();
      }
      setStartX(null); // Reset startX so that the swipe is only counted once
    }
  };

  // close the translations panel when the user swipes down on it
  // NOTE: this works even when the panel is not full screen
  const [startY, setStartY] = useState(null); // New state variable for Y-coordinate

  const handleTouchStartTranslationsPanel = (e) => {
    setStartY(e.touches[0].clientY); // Store the Y-coordinate at touch start
  };

  const handleTouchMoveTranslationsPanel = (e) => {
    // if a selection was made, don't count it as a swipe
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return; // Exit function if text is selected
    }

    if (startY === null) {
      return;
    }

    const yDiff = startY - e.touches[0].clientY; // Calculate Y-coordinate difference

    // if the user is scrolling down more than 50% of the viewport height, close the translations panel
    // originally used just 500 in absolute units but it's less reliable bc screen sizes vary
    if ((Math.abs(yDiff) / viewportHeight) * 100 > 50) {
      // Threshold for minimal swipe distance
      if (yDiff < 0) {
        closeTranslationsPanel(); // Call closeTranslationsPanel on swipe down
        // hide translations panel
        document.getElementById("translations").style.display = "none";
      }

      setStartY(null); // Reset startY so that the swipe is only counted once
    }
  };

  function calculatePages(lastLearnedWordIndex) {
    if (showFinishedPage)
      return console.log(
        "Article is finished, no need to recalculate pages: ",
        lastLearnedWordIndex
      );
    console.log(
      "Starting to calculate pages with lastLearnedWordIndex: ",
      lastLearnedWordIndex,
      "and the word is: ",
      tokens[lastLearnedWordIndex]
    );
    let pages = [];
    let i = 0;
    // create a loop that finishes when the i is equal to the length of the tokens array
    while (i < tokens.length) {
      let endingWordIndex = insertWords(i);
      // NOTE: this only works when the screen is not high enough to fit even one word
      // BUG: the same problem can happen because of the translation panel
      if (endingWordIndex === startingWordIndex) {
        // setOffsetHeight(document.documentElement.clientHeight);
        // BUG: pinching in mobile Chrome goes into endless loop: https://github.com/bokand/bokand.github.io/blob/master/web_viewports_explainer.md
        console.log(
          "Your screen is too small. Please use a bigger screen or switch to portrait mode on your mobile device."
        );
        setPopupWarningMessage(
          "Please make sure that there are no popups open - close them and then refresh the page. Contact the support if the problem persists."
        );
        return;
      } else {
        setPopupWarningMessage(false);
      }
      pages.push({ start: i, end: endingWordIndex });
      i = endingWordIndex + 1;
    }
    console.log("PAGES: ", pages);
    // find in which page the lastLearnedWordIndex - it has to be between pages.start and pages.end values
    const pageIndex = pages.findIndex((page) => {
      return (
        lastLearnedWordIndex >= page.start && lastLearnedWordIndex <= page.end
      );
    });
    console.log("PAGE INDEX: ", pageIndex);
    setArticlePages(pages);
    setCurrentArticlePage(pageIndex);
    insertWords(pages[pageIndex].start);

    // NOTE: for testing on mobile only
    // setArticlePages(pages);
    // setCurrentArticlePage(0);
    // insertWords(0);
  }

  // handle mouse clicks and touch events and selection
  useEffect(() => {
    // Get the element by ID
    const textContentElement = document.getElementById("textContent");
    const leftMenu = document.getElementById("leftMenu");
    const rightMenu = document.getElementById("rightMenu");

    // Add event listeners if the element exists
    // NOTE: can't add to document because it doesn't work on mobile - translation panel clicks would be counted as well
    if (textContentElement) {
      textContentElement.addEventListener("mouseup", handleTextSelection);
      textContentElement.addEventListener("touchend", handleTextSelection); // for touch screens
      leftMenu.addEventListener("mouseup", handleTextSelection);
      leftMenu.addEventListener("touchend", handleTextSelection);
      rightMenu.addEventListener("mouseup", handleTextSelection);
      rightMenu.addEventListener("touchend", handleTextSelection);

      // Selection change event
      textContentElement.addEventListener("selectionchange", () => {
        if (isSelecting) {
          console.log("Selection is ongoing...");
          // add selection to the logs
          setDebuggingLog("selection in progress...");
        }
      });

      // For desktop (mouse interaction)
      textContentElement.addEventListener("mousedown", () => {
        isSelecting = true;
        setDebuggingLog("selection in progress...");
      });

      textContentElement.addEventListener("mouseup", () => {
        if (isSelecting) {
          const selection = window.getSelection().toString();
          if (selection.length > 0) {
            console.log("Selection completed:", selection);
            // add selection to the logs
            setDebuggingLog(selection.toString());
          }
          isSelecting = false;
        }
      });

      // For mobile (touch interaction)
      textContentElement.addEventListener("touchstart", () => {
        isSelecting = true;
      });

      textContentElement.addEventListener("touchend", () => {
        if (isSelecting) {
          const selection = window.getSelection().toString();
          if (selection.length > 0) {
            console.log("Selection completed:", selection);
          }
          isSelecting = false;
        }
      });
    }

    // Cleanup function to remove event listeners
    return () => {
      if (textContentElement) {
        document.removeEventListener("mouseup", handleTextSelection);
        document.removeEventListener("touchend", handleTextSelection); // for touch screens
        leftMenu.removeEventListener("mouseup", handleTextSelection);
        leftMenu.removeEventListener("touchend", handleTextSelection);
        rightMenu.removeEventListener("mouseup", handleTextSelection);
        rightMenu.removeEventListener("touchend", handleTextSelection);
      }
    };
  }, []);

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    console.log("SELECTION CHANGE: ", selection);
    // add selection to the logs
    setDebuggingLog((prevLogs) => [...prevLogs, selection.toString()]);
  };

  const handleTextSelection = (e) => {
    const selection = window.getSelection();
    console.log("SELECTION: ", selection);
    console.log("SELECTION EVENT: ", e);

    // if the click is on the translation panel, do nothing - don't clear the selection or highlights
    let anchorNode = selection.anchorNode;
    if (anchorNode && anchorNode.nodeType === Node.TEXT_NODE) {
      anchorNode = anchorNode.parentNode;
    }
    // alert("anchorNode: " + anchorNode.classList)

    // if the click is on the translation panel, do nothing - don't clear the selection or highlights
    const propExists = checkTranslationPanelProp(anchorNode);
    if (anchorNode) {
      console.log(`Does --translation-panel exist on the parent?`, propExists);
      if (propExists) {
        // alert("Prop exists: " + propExists)
        // BUG: it does not work on mobile with the full screen panel and text selection
        // BUG: on mobile the text selection doesn't work across the lines - the space between the lines is not included in the selection
        return;
      }
    }

    clearTranslationPanelSelectedWord();

    // return;

    // phrase selection
    if (selection.rangeCount > 0 && selection.type === "Range") {
      const selectedText = selection.toString().trim();
      const words = selectedText.split(/\s+/).filter((word) => /\w/.test(word)); // Split and filter out non-word elements
      console.log("WORDS: ", words.length, words);

      console.log("Number of words in selection:", words.length); // Log the number of words

      if (words.length > 10) {
        // NOTE: The delay is only needed for iOS - it doesn't work without it
        setTimeout(() => {
          selection.removeAllRanges();
        }, 100);
        return console.log(
          "The selection contains too many words - not sending it to translation."
        );
      }

      const baseNode = selection.baseNode;
      // extentNode is the last node in the selection
      // baseNode is the first node in the selection
      const extentNode = selection.extentNode;
      console.log("baseNode: ", baseNode);
      // console.log("baseNode parent's sentence: ", baseNode.parentElement.getAttribute('data-sentence'));
      console.log("extentNode: ", extentNode);
      let firstWordIndex = parseInt(baseNode.parentElement.id.split("-")[1]);
      console.log("firstWordIndex: ", firstWordIndex);
      let lastWordIndex = parseInt(extentNode.parentElement.id.split("-")[1]);
      console.log("lastWordIndex: ", lastWordIndex);
      // switch the indexes if the selection was made from right to left
      if (lastWordIndex < firstWordIndex) {
        const temp = lastWordIndex;
        lastWordIndex = firstWordIndex;
        firstWordIndex = temp;
      }

      // loop through the words between firstWordIndex and lastWordIndex
      let selectedPhrase = { word: "", is_phrase: false };
      for (let i = firstWordIndex; i <= lastWordIndex; i++) {
        // highlight the word
        const word = document.getElementById(`word-${i}`);
        selectedPhrase.word += word.innerText;
        selectedPhrase.is_phrase = true;
        word.classList.add("highlighted");
      }
      // clear selection
      // NOTE: the delay is only needed for iOS - it doesn't work without it
      setTimeout(() => {
        selection.removeAllRanges();
      }, 100);
      document.getElementById("textContent").focus();
      // clear spaces from both sides of the selected text
      selectedPhrase.word = selectedPhrase.word.trim();
      selectedPhrase.word = removePunctuation(selectedPhrase.word);
      selectedPhrase.sentence =
        baseNode.parentElement.getAttribute("data-sentence");
      console.log("Selected phrase: ", selectedPhrase);
      // send the selected text to the translated panel displayer
      setSelectedWordOriginalTextState(selectedPhrase);
    } else {
      setSelectedWordOriginalTextState("");
    }
  };

  // Function to remove punctuation from the start and end of a string
  function removePunctuation(str) {
    return str.replace(/^\p{P}+|\p{P}+$/gu, "");
  }

  // BUG: when clicking on a blue word after a blue word was clicked, the translation doesn't show up
  // it shows up when clicking on a known first though

  function handleWordClick(event) {
    // const clickedWord = event.target.textContent;
    const clickedWord = tokens[parseInt(event.target.dataset.index)];
    console.log(`Clicked word:`, clickedWord);
    // if (clickedWord?.translation === undefined && checkIfFreePlanLimitReached()) return;

    console.log("Highlighting the word: ", event.target);
    event.target.classList.add("highlighted");
    setSelectedWordOriginalTextState(clickedWord);
  }

  function closeTranslationsPanel() {
    document.getElementById("translations").style.display = "none";
    // this is for mobile only to make sure there isn't a window scrollbar that would start showing content when scrolling on mobile
    document.getElementById("textContent").style.display = "block";
    clearTranslationPanelSelectedWord();
  }

  // NOTE: even if I refresh the page, I still have to deal with word highlighting as well somehow
  function refreshPage() {
    insertWords(articlePages[currentArticlePage].start);
  }

  function reloadPage() {
    window.location.reload();
  }

  function getNextPage() {
    setWordForQuickLookup(null);
    markUnknownWordsAsKnown();
    // update the percent_complete value in the user library
    let percentComplete = Math.round(
      (endingWordIndex / (tokens.length - 1)) * 100
    );
    // create a new array with the words that were read
    let wordsRead = [];
    for (let i = startingWordIndex; i <= endingWordIndex; i++) {
      // push only if the word is an actual word
      // REVIEW: is_not_a_word also includes numbers
      if (!tokens[i].is_not_a_word && tokens[i].word.trim().length > 0)
        wordsRead.push(tokens[i]);
    }
    console.log("WORDSREAD: ", wordsRead);
    addArticleToUserLibrary(
      currentArticle._id,
      percentComplete,
      wordsRead.length
    );
    if (endingWordIndex === tokens.length - 1) {
      // alert("No more words to show");
      setShowFinishedPage(true);
      return;
    }
    // set startingWordIndex to endingWordIndex + 1
    // TODO: this has to be moved into useEffect or I need to send the index as a parameter cause it's not immediately updated
    console.log(
      `Setting the starting and ending word indexes to for the next page to: ${
        endingWordIndex + 1
      } and ${endingWordIndex + 10}`
    );
    setStartingWordIndex(endingWordIndex + 1);
    // console.log("STARTING WORD INDEX:", startingWordIndex)
    // console.log("WHAT WAS SET:", endingWordIndex + 1);
    // TODO: has to have a value
    insertWords(endingWordIndex + 1);
    setCurrentArticlePage(currentArticlePage + 1);
  }

  function getPreviousPage() {
    setWordForQuickLookup(null);
    if (startingWordIndex === 0 || currentArticlePage === 0) {
      // alert("Already on the first page.");
      return;
    }
    console.log("ARTICLE PAGES:", articlePages);
    // NOTE: there is currently duplication - I already know the starting and ending indexes, no need to calculate them as well inside the insertWords function
    insertWords(articlePages[currentArticlePage - 1].start);
    setCurrentArticlePage(currentArticlePage - 1);
    setStartingWordIndex(articlePages[currentArticlePage - 1].start);
  }

  function markUnknownWordsAsKnown() {
    // loop through the words between startingWordIndex and endingWordIndex
    let wordsToUpdate = [];
    for (let i = startingWordIndex; i <= endingWordIndex; i++) {
      // check if the word is a word and unknown
      if (tokens[i].is_not_a_word || tokens[i].known) {
        continue;
      }
      // add the word to the wordsToUpdate array
      // REVIEW: not sure if this is needed anymore - maybe the backend query somehow handles it anyway
      tokens[i].known = true;
      tokens[i].strength = 5;
      tokens[i].translation = undefined;
      wordsToUpdate.push(tokens[i]);
    }
    // remove duplicates from the wordsToUpdate array
    wordsToUpdate = wordsToUpdate.filter(
      (word, index, self) =>
        index === self.findIndex((w) => w.word === word.word)
    );
    console.log("WORDS TO UPDATE after removing duplicates: ", wordsToUpdate);
    sendSkippedWordsToBacked(wordsToUpdate);
  }

  function sendSkippedWordsToBacked(wordsToUpdate) {
    if (wordsToUpdate.length === 0)
      return console.log("No skipped words to update in the backend.");

    // Extracting only the needed properties from each word object (otherwise the payload would be too big)
    const wordsToSend = wordsToUpdate.map(({ word, strength }) => ({
      word,
      strength,
    }));

    console.log(
      "Sending these skipped words to backend for updating: ",
      wordsToSend
    );

    // send a put request to the api to update the word
    const request = {
      words: wordsToSend,
    };
    axios
      .put("/api/user/vocabulary/add-skipped-words", request)
      .then((response) => {
        console.log(
          "RESPONSE FROM UPDATE SKIPPED WORDS backend: ",
          response.data
        );
        // Assuming `setKnownWordsArray` and `knownWordsArray` are defined elsewhere
        // add a small delay to make sure the progress bar animation is complete
        setTimeout(() => {
          setKnownWordsArray(knownWordsArray.concat(response.data.addedWords));
        }, 500);
        console.log("Updated known words array with skipped words.");
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING SKIPPED WORDS in backend:", err);
      });
  }

  function sendWordsToUpdateToBackend(wordsToUpdate) {
    if (wordsToUpdate.length === 0)
      return console.log("No words to update in the backend.");
    console.log("Sending these words to backend for updating: ", wordsToUpdate);
    // send a put request to the api to update the word
    const request = {
      words: wordsToUpdate,
    };
    axios
      .put("/api/user/update-words", request)
      .then((response) => {
        // setKnownWordsArray(knownWordsArray.concat([response.data.added]));
        // console.log("Added new known word: ", newWord);
        console.log("RESPONSE FROM UPDATE WORDS backend: ", response.data);
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING WORDS in backend:", err);
      });
  }

  useEffect(() => {
    const translationPanelIsVisible =
      document.getElementById("translations").style.display;

    // Function to update the viewport height
    const updateViewportHeight = () => {
      if (translationPanelIsVisible === "block") {
        return;
      }
      // if (viewportHeight === 0) {
      // NOTE: would use window.innerHeight otherwise but it doesn't work on mobile properly when the address bar is visible
      setViewportHeight(document.documentElement.clientHeight);
      // }
    };

    // Function to update the offset height
    const updateOffsetHeight = () => {
      if (translationPanelIsVisible === "block") {
        return;
      }
      const textContentDiv = document.getElementById("readerContainer");
      setOffsetHeight(document.documentElement.scrollHeight);
    };

    // Function to update the viewport width
    const updateViewportWidth = () => {
      if (translationPanelIsVisible === "block") {
        return;
      }
      // if (viewportHeight === 0) {
      // NOTE: would use window.innerHeight otherwise but it doesn't work on mobile properly when the address bar is visible
      setViewportWidth(document.documentElement.clientWidth);
      // }
    };

    // Initial updates
    updateViewportHeight();
    updateOffsetHeight();
    updateViewportWidth();

    // Attach the event listeners for window resize
    window.addEventListener("resize", updateViewportHeight);
    window.addEventListener("resize", updateViewportWidth);
    window.addEventListener("resize", updateOffsetHeight);
    // add listener for orientation change
    window.addEventListener("orientationchange", updateViewportHeight);

    // Remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
      window.removeEventListener("resize", updateOffsetHeight);
      window.removeEventListener("resize", updateViewportWidth);
      // remove listener for orientation change
      window.removeEventListener("orientationchange", updateViewportHeight);
    };
  }, []);

  function insertWords(startingIndex) {
    console.log("Starting to insert the words...");
    console.log("KNOWN WORDS ATM: ", knownWordsArray);
    console.log("startingIndex: ", startingIndex);

    let indexToReturn;
    // Create a reference to the 'textContentWords' div
    const textContentDiv = document.getElementById("readerContainer");
    const textContentWordsDiv = document.getElementById("textContentWords");

    let currentHeight = document.documentElement.scrollHeight;
    // clear up any words on the page
    textContentWordsDiv.innerHTML = "";

    console.log("TOKENS BEFORE INSERT WORDS LOOP: ", tokens);

    for (let i = startingIndex; i < tokens.length; i++) {
      // console.log("startingWordIndex: ", startingIndex);
      // console.log("i: ", i)
      const word = tokens[i];
      let wordDiv;

      if (word.word === "\n\n") {
        // insert two <br> as the last children of the textContentWordsDiv
        wordDiv = document.createElement("p");
        textContentWordsDiv.appendChild(wordDiv);
      } else {
        // Create a new div element for the word
        wordDiv = document.createElement("span");
        wordDiv.textContent = word.word;

        // Append the div to the 'textContentWords' div
        textContentWordsDiv.appendChild(wordDiv);

        wordDiv.classList.add("word");
        // check if the learning language code is thai
        if (context.getSelectedLanguagePair().language_learning.code === "th") {
          wordDiv.classList.add("thai");
        }
        // add id to the word based on the index
        wordDiv.id = `word-${i}`;
        // add index as a data attribute
        wordDiv.dataset.index = i;
        wordDiv.dataset.known = word.known;
        wordDiv.dataset.translation = word.translation;
        wordDiv.dataset.strength = word.strength;
        // console.log(
        //   `The word is ${word.word} and the strength is:`,
        //   word.strength
        // );
        wordDiv.dataset._id = word._id;
        wordDiv.dataset.is_title = word.is_title;
        wordDiv.dataset.is_last_title_word = word.is_last_title_word;
        wordDiv.dataset.is_not_a_word = word.is_not_a_word;
        wordDiv.dataset.is_number = word.is_number;
        wordDiv.dataset.sentence = word.sentence;

        // if the word is a title, add the title class
        if (word.is_title) {
          wordDiv.classList.add("title");
          if (word.is_last_title_word) {
            const pElement = document.createElement("p");
            textContentWordsDiv.appendChild(pElement);
          }
        }

        // check if the word is known and add the appropriate class
        if (word.known) {
          if (word.translation) {
            wordDiv.classList.add("known");
            wordDiv.classList.add("known-strength-" + word.strength);
          } else {
            wordDiv.classList.add("skipped");
          }
          wordDiv.addEventListener("click", handleWordClick);
        } else if (word.is_not_a_word) {
          wordDiv.classList.add("punctuation");
          if (word.is_number) {
            wordDiv.classList.add("number");
            wordDiv.addEventListener("click", handleWordClick);
          }
        } else {
          wordDiv.classList.add("unknown");
          wordDiv.addEventListener("click", handleWordClick);
        }
      }

      currentHeight = document.documentElement.offsetHeight;

      // NOTE: an attempt to fix the problem with browser password prompts etc
      // the assumption is that when inserting words, the individual elements would always be smaller than 40px
      if (
        currentHeight - viewportHeight > 40 &&
        currentHeight - viewportHeight < 50
      ) {
        // alert("problem");
        currentHeight = viewportHeight;
      }

      // Check if the current height is greater than the viewport height
      // NOTE: added +1 here just bc zooming tends to trigger sometimes this otherwise
      if (currentHeight > document.documentElement.clientHeight + 1) {
        console.log(
          "Current height is bigger than viewportHeight:",
          currentHeight,
          ">",
          viewportHeight
        );
        console.log(
          "Total document height is: ",
          document.documentElement.clientHeight
        );
        if (i === startingIndex) {
          return startingIndex;
        }
        // remove the last word
        wordDiv.remove();
        setEndingWordIndex(i - 1);
        // check if lastchild exists for textContentWordsDiv, if so, remove it
        if (
          textContentWordsDiv.lastChild &&
          textContentWordsDiv.lastChild.innerHTML === ""
        ) {
          textContentWordsDiv.lastChild.remove();
        }
        console.log(
          "Current height after removing the last item: ",
          document.documentElement.offsetHeight
        );
        console.log("ENDING WORD INDEX: ", i - 1);
        return i - 1;
      }
      setEndingWordIndex(i);
      // return i;
      indexToReturn = i;
    }
    console.log("ENDING WORD INDEX: ", indexToReturn);
    // TODO: setEndingWordIndex is pointless to keep in the state here I think
    return indexToReturn;
  }

  useEffect(() => {
    console.log("Viewportheight changed: ", viewportHeight);
    if (viewportHeight === 0) {
      console.log("Viewportheight is 0, so, not gonna calculate anything yet.");
      return;
    }
    if (tokens.length === 0) {
      console.log("No tokens yet, so, not gonna calculate anything yet.");
      return;
    }
    let lastLearnedWordIndex = 0;
    // BUG: if the screen size gets too small when resizing on desktop, the loop will run forever
    calculatePages(lastLearnedWordIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportHeight]);

  function clearTranslationPanelSelectedWord() {
    setSelectedWordOriginalTextState({});
    setWordForFullLookup(null);
    setWordForQuickLookup(null);
    console.log("you");
    const highlightedWords = document.querySelectorAll(".highlighted");
    highlightedWords.forEach((word) => {
      word.classList.remove("highlighted");
    });
  }

  function addUnknownWordToKnownWords(word, translation, strength) {
    let newWord = {
      word: word,
      translation: translation,
      strength: strength,
    };
    console.log("Sending this word to backend for updating: ", newWord);
    context.saveNewWordToBackend(newWord, currentArticle._id);
    return;
  }

  function updateKnownWordInKnownWords(wordToUpdate, translation, strength) {
    console.log("checking updated known word: ", wordToUpdate);
    console.log("the known word translation is: ", translation);
    console.log("the strength of the updated word should be: ", strength);
    // NOTE: the strength for the word that was skipped is 4, it's not updated here to 0 when meaning is added
    // find the index of the word to update
    const index = knownWordsArray.findIndex(
      (knownWord) => knownWord._id === wordToUpdate._id
    );
    // update the word in the array
    let newWord = knownWordsArray[index];
    // // let the user update a translation only if the word already had a translation (and wasn't therefore skipped)
    // if (newWord?.translation === undefined && translation !== undefined)
    //   return;
    console.log("NEW WORD: ", newWord);
    console.log("wordToUpdate: ", wordToUpdate);
    newWord.translation = translation;
    // NOTE: existing strength could also be 0
    newWord.strength =
      wordToUpdate.strength !== undefined && wordToUpdate.strength !== null
        ? wordToUpdate.strength
        : strength;
    if (newWord.strength !== strength) newWord.strength = strength;
    // set the state variable to the new array without referencing the old one
    setKnownWordsArray([...knownWordsArray]);

    console.log("Updated known word: ", newWord);
    sendWordsToUpdateToBackend([newWord]);
  }

  // useEffect for showFinishedPage
  useEffect(() => {
    console.log("showFinishedPage changed: ", showFinishedPage);
  }, [showFinishedPage]);

  const handleOpenNewArticle = () => {
    // Perform a full page refresh to reset all states
    // Otherwise the simplification message would stay open, the tooltips as well etc
    window.location.href = `/reader/${simplificationMessage}`;
  };

  if (showFinishedPage) {
    return (
      <ReaderFinishedPage
        knownWordsArray={knownWordsArray}
        knownWordsAtTheStart={knownWordsAtTheStart}
        currentArticle={currentArticle}
        articleId={currentArticle?._id}
        audioUrl={audioUrl}
        // generateAudioForArticle={generateAudioForArticle}
        generateAudioIsProcessing={generateAudioIsProcessing}
        setGenerateAudioIsProcessing={setGenerateAudioIsProcessing}
        showAudioIsProcessingModal={showAudioIsProcessingModal}
        setShowAudioIsProcessingModal={setShowAudioIsProcessingModal}
        setAudioUrl={setAudioUrl}
        setShowPlaylistSelectionModal={setShowPlaylistSelectionModal}
        showPlaylistSelectionModal={showPlaylistSelectionModal}
      />
    );
  }

  function handleSimplifyWithAIButtonClick(simplification_method = "simple") {
    console.log("Sending article ID to simplification: ", currentArticle._id);
    setSimplificationMessage(false);
    setSimplificationIsProcessing(true);
    console.log("Simplification method: ", simplification_method);
    axios
      .post("/api/simplify-article", {
        article_id: currentArticle._id,
        language_learning: context.getSelectedLanguagePair().language_learning,
        simplification_method: simplification_method,
      })
      .then((data) => {
        setSimplificationIsProcessing(false);
        console.log("Article simplified successfully");
        setSimplificationMessage(data.data.article_id);
        console.log("Simplification message: ", data.data.article_id);
      })
      .catch((error) => {
        setSimplificationIsProcessing(false);
        setSimplificationError(true);
        setSimplificationMessage(false);
        console.error("Error simplifying article:", error);
      });
  }

  function openOriginalArticle() {
    window.open(currentArticle.original_url, "_blank");
  }

  return (
    <>
      <Container id="readerContainer" fluid="md">
        <Row style={{ minHeight: "100svh" }}>
          {/* NOTE: when using vw for padding, it tends to crash the Chrome responsive viewer when switching from mobile to desktop */}
          {/* reader column */}
          <Col className="d-flex flex-column">
            {/* close button, progress bar, help button */}
            <Row className="justify-content-center align-items-center pt-3">
              <Col xs={2} md={1} className="d-flex justify-content-center">
                <Link to="/library">
                  <Button
                    id="readerCloseReaderButton"
                    variant="light"
                    size="sm"
                  >
                    &#10006;
                  </Button>
                </Link>
              </Col>
              <Col xs={8} md={10} className="text-center">
                {showDebuggingInfo && (
                  <div id="debuggingInfo">
                    <Button
                      variant="outline-dark"
                      className="mb-2"
                      onClick={refreshPage}
                      size="sm"
                    >
                      Reload contents
                    </Button>
                    <h6>Debugging log</h6>
                    <div id="debuggingLog">{debuggingLog}</div>

                    <div
                      style={{
                        display:
                          process.env.NODE_ENV === "development"
                            ? "block"
                            : "none",
                      }}
                    >
                      {/* <h6 className="mt-3">Debugging info</h6> */}
                      {/* <div className="debuggingInfo">
                        Viewport Height: {viewportHeight} pixels
                      </div>
                      <div className="debuggingInfo">
                        Offset Height: {offsetHeight} pixels
                      </div>
                      <div className="debuggingInfo">
                        Viewport Width: {viewportWidth} pixels
                      </div>
                      <div className="debuggingInfo">
                        Starting word index: {startingWordIndex}
                      </div>
                      <div className="debuggingInfo">
                        Ending word index: {endingWordIndex}
                      </div>
                      <div
                        className="debuggingInfo"
                        style={{ marginBottom: "1em" }}
                      >
                        Current article page: {currentArticlePage + 1} /{" "}
                        {articlePages.length}
                      </div> */}
                    </div>
                  </div>
                )}
                <div id="progressBar">
                  <ProgressBar
                    now={currentArticlePage}
                    min={0}
                    max={articlePages.length - 1}
                    variant={
                      currentArticlePage === articlePages.length - 1 &&
                      "success"
                    }
                    style={{ height: "5px" }}
                  />
                </div>
              </Col>
              <Col xs={2} md={1} className="d-flex justify-content-center mb-1">
                <div>
                  <Dropdown align="end" autoClose="outside">
                    <Dropdown.Toggle
                      as="button"
                      variant="link"
                      className="p-0 border-0 bg-transparent no-caret"
                    >
                      <ThreeDots size={20} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Header>Reader settings</Dropdown.Header>
                      <Dropdown.Item className="d-flex align-items-center">
                        <Form>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={quickLookupRef.current}
                            onChange={() => {
                              context.toggleQuickLookupInReader();
                              quickLookupRef.current = !quickLookupRef.current;
                              console.log(
                                `[${new Date().toISOString()}] Toggling open trans panel by default: new=${!context
                                  .settings.quick_lookup_in_reader}`
                              );
                            }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Form>
                        <div className="fs-6">
                          Quick word lookup in News Reader
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Header>Global settings</Dropdown.Header>
                      <Dropdown.Item className="d-flex align-items-center">
                        <Form>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={expandAIExplanationBoxRef.current}
                            onChange={() => {
                              context.toggleExpandAIExplanationBox();
                              expandAIExplanationBoxRef.current =
                                !expandAIExplanationBoxRef.current;
                              console.log(
                                `[${new Date().toISOString()}] Toggling expand ai explanation box: new=${!context
                                  .settings.expand_ai_explanation_box}`
                              );
                            }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Form>
                        <div className="fs-6">
                          Expand AI explanation box by default
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {currentArticle?.original_url && (
                        <>
                          <Dropdown.Item
                            onClick={openOriginalArticle}
                            className="d-flex align-items-center"
                          >
                            <BoxArrowUpRight className="me-2" /> Open original
                            article
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </>
                      )}
                      <Dropdown.Item
                        onClick={openSupportCenter}
                        className="d-flex align-items-center"
                      >
                        <InfoCircle className="me-2" /> Support center
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={sendBugReport}
                        className="d-flex align-items-center"
                      >
                        <Bug className="me-2" /> Send bugs & feedback
                      </Dropdown.Item>
                      {/* add a button for showing debugging info */}
                      <Dropdown.Item
                        onClick={() => setShowDebuggingInfo(!showDebuggingInfo)}
                        className="d-flex align-items-center"
                      >
                        <Bug className="me-2" />{" "}
                        {showDebuggingInfo ? "Hide" : "Show"} debugging info
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            {/* reader and prev/next page buttons */}
            <Row className="flex-grow-1">
              {/* prev page button */}
              <Col
                xs={1}
                // style={{
                //   // border: showDebuggingInfo ? "1px solid blue" : "none",
                //   padding: "3vw",
                //   // display: "flex",
                //   // alignItems: "center",
                //   // justifyContent: "space-between",
                //   // flexDirection: "column", // Step 1
                // }}
                className="d-flex justify-content-center align-items-center"
                id="leftMenu"
              >
                <Button
                  variant="light"
                  onClick={getPreviousPage}
                  size="lg"
                  id="readerPreviousPageButton"
                  className="arrowButton"
                  style={{
                    display: currentArticlePage === 0 ? "none" : "inline-block",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                </Button>
              </Col>
              {/* reader text contents */}
              <Col
                style={
                  {
                    // border: showDebuggingInfo ? "1px solid black" : "none",
                  }
                }
                id="textContent"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onContextMenu={handleContextMenu}
                className="flex-grow-1"
              >
                <div id="textContentWords"></div>
              </Col>
              {/* next page button */}
              <Col
                xs={1}
                // style={{
                //   // border: showDebuggingInfo ? "1px solid blue" : "none",
                //   padding: "3vw",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   // flexDirection: "column", // Step 1
                // }}
                id="rightMenu"
                className="d-flex justify-content-center align-items-center"
              >
                <OverlayTrigger
                  placement="left"
                  // set show property to false when visualviewport width is less than 480, otherwise don't use show property
                  show={
                    document.documentElement.clientWidth < 1024 ||
                    knownWordsArray === null ||
                    knownWordsArray.length > 0
                      ? false
                      : undefined
                  }
                  delay={{ show: 50, hide: 400 }}
                  overlay={
                    <Tooltip
                      id={`tooltip-next-page`}
                      bsPrefix="nextButtonTooltip"
                    >
                      <strong>Go to the next page.</strong> <br />
                      And mark all <span className="unknown">
                        new words
                      </span> as <span className="skipped">skipped words</span>{" "}
                      (known words).
                    </Tooltip>
                  }
                  popperConfig={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10], // Change the numbers to control x, y offset
                        },
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          padding: 10, // Change this value to control padding
                        },
                      },
                      {
                        name: "flip",
                        options: {
                          padding: 10, // Change this value to control padding
                        },
                      },
                    ],
                  }}
                >
                  <Button
                    variant="light"
                    onClick={getNextPage}
                    id="readerNextPageButton"
                    size="lg"
                    className="arrowButton"
                  >
                    {/* <span>&rsaquo;</span> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
            {/* footer */}
            <Row className="mb-3">
              {/* Audio button */}
              <Col className="d-flex justify-content-center">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    size="lg"
                    className="custom-dropdown-toggle d-flex justify-content-center align-items-center mx-2"
                  >
                    {generateAudioIsProcessing ? (
                      <Spinner animation="grow" size="sm" className="me-0" />
                    ) : (
                      <Headphones />
                    )}
                    <span className="mx-2">Listen</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      // href="#/action-1"
                      onClick={() => {
                        setGenerateAudioIsProcessing(true);
                        setShowAudioIsProcessingModal(true);
                      }}
                      disabled={generateAudioIsProcessing}
                    >
                      Generate audio for the content
                    </Dropdown.Item>
                    <Dropdown.Item
                      // href="#/action-2"
                      onClick={() => {
                        if (audioUrl) {
                          navigate("/player/" + currentArticle._id);
                        }
                      }}
                      disabled={!audioUrl}
                    >
                      Listen to the article
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowPlaylistSelectionModal(true);
                      }}
                      disabled={!audioUrl}
                    >
                      Add to playlist
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    size="lg"
                    className="custom-dropdown-toggle d-flex justify-content-center align-items-center mx-2"
                  >
                    {simplificationIsProcessing ? (
                      <Spinner animation="grow" size="sm" className="me-0" />
                    ) : (
                      <Magic className="me-0" />
                    )}
                    <span className="mx-2">Simplify</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      // href="#/action-1"
                      onClick={() => handleSimplifyWithAIButtonClick("simple")}
                      disabled={simplificationIsProcessing}
                    >
                      Simplify with AI
                    </Dropdown.Item>
                    <Dropdown.Item
                      // href="#/action-1"
                      onClick={() => handleSimplifyWithAIButtonClick("ghetto")}
                      disabled={simplificationIsProcessing}
                    >
                      Simplify with AI and make it ghetto
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>

          {/* translation panel */}
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={3}
            style={{}}
            id="translations"
            tabIndex="0"
            className={isFullScreen ? "full-screen" : ""}
            onTouchStart={handleTouchStartTranslationsPanel}
            onTouchMove={handleTouchMoveTranslationsPanel}
          >
            <Button
              variant="light"
              onClick={closeTranslationsPanel}
              id="closeTranslationsPanelButton"
              className={
                isFullScreen ? "closeTranslationsPanelButtonBigger" : ""
              }
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              &#x2715;
            </Button>
            <TranslationPanel
              selectedWord={wordForFullLookup}
              setSelectedWord={setWordForFullLookup}
              refreshPage={refreshPage}
              addUnknownWordToKnownWords={addUnknownWordToKnownWords}
              updateKnownWordInKnownWords={updateKnownWordInKnownWords}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              closeTranslationsPanel={closeTranslationsPanel}
              fullTextTokens={tokens}
              clearTranslationPanelSelectedWord={
                clearTranslationPanelSelectedWord
              }
            />
            {/* <strong>Word translations</strong> */}
          </Col>
        </Row>
      </Container>

      <QuickTranslationTooltip
        selectedWord={wordForQuickLookup}
        setSelectedWord={setWordForQuickLookup}
        setWordForFullLookup={setWordForFullLookup}
        addUnknownWordToKnownWords={addUnknownWordToKnownWords}
        updateKnownWordInKnownWords={updateKnownWordInKnownWords}
        speakOnRender={true}
      />

      <Modal
        show={popupWarningMessage}
        centered={true}
        onHide={() => {
          setPopupWarningMessage(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Could not load the content properly</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>What to do?</h5>
          <ul>
            <li>
              <strong>Close all the browser popups and prompts</strong>{" "}
              (password saving prompts, web dev console etc.)
            </li>
            <li>
              <strong>Refresh the page</strong>
            </li>
          </ul>
          <p>
            Please make sure that you're using a proper browser (Chrome, Edge,
            Safari etc.). In-app browsers in Facebook, Messenger etc. apps might
            not work properly.
          </p>
          <p>
            If the problem persists, please contact the{" "}
            <a href="https://lingochampion.freshdesk.com" target="_blank">
              support
            </a>
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={reloadPage}>
            <ArrowClockwise className="me-2" />
            Reload the page
          </Button>
          <Button
            variant="dark"
            onClick={() => {
              setPopupWarningMessage(false);
            }}
          >
            Okay, got it
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={simplificationMessage}
        centered={true}
        onHide={() => {
          setSimplificationMessage(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Robot className="me-2 mb-1" />
            Article simplification is complete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You can find the simplified version under the "Imported" tab in your
          Library.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-dark"
            onClick={() => {
              navigate("/library?tab=imported");
            }}
          >
            Show it in Library
          </Button>
          <Button variant="primary" onClick={handleOpenNewArticle}>
            Open the new article
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={simplificationError}
        centered={true}
        onHide={() => {
          setSimplificationError(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Robot className="me-2 mb-1" color="red" />
            AI messed up and ran into an error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This feature is still in beta, so, errors happen sometimes.{" "}
          <strong>Please try again</strong> or contact support if this error
          persists.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setSimplificationError(false);
            }}
          >
            Okay, whatever
          </Button>
        </Modal.Footer>
      </Modal>

      <GeneratingAudioModal
        articleId={currentArticle?._id}
        generateAudioIsProcessing={generateAudioIsProcessing}
        setGenerateAudioIsProcessing={setGenerateAudioIsProcessing}
        show={showAudioIsProcessingModal}
        setShow={setShowAudioIsProcessingModal}
        setAudioUrl={setAudioUrl}
        setShowPlaylistSelectionModal={setShowPlaylistSelectionModal}
      />

      <PlaylistModal
        show={showPlaylistSelectionModal}
        article={currentArticle}
        setShow={setShowPlaylistSelectionModal}
      />
    </>
  );
}

function createWords(wordsArray, knownWords, inputType) {
  let words = [];
  for (let i = 0; i < wordsArray.length; i++) {
    // console.log("Creating word: ", wordsArray[i]);
    // let word = {};

    // Extract the token from the word object
    // NOTE: only necessary for Thai which already has the .word property
    // Use existing 'word' property if available, otherwise default to 'token'
    let word =
      wordsArray[i].word !== undefined
        ? {
            word: wordsArray[i].word,
            sentence: escapeHTML(wordsArray[i].sentence),
          }
        : {
            word: wordsArray[i].token,
            sentence: escapeHTML(wordsArray[i].sentence),
          };

    // console.log("The word is: ", word);

    // Rest of your existing code...
    word.known = false;
    word.is_not_a_word = false;
    word.is_number = false;
    if (inputType === "title") {
      word.is_title = true;
      if (i === wordsArray.length - 1) {
        word.is_last_title_word = true;
      }
    } else {
      word.is_title = false;
    }
    const regex = /[\p{P}\p{Z}\p{N}]/gu; // punctuation, separator, number
    const got_punctuation = word.word.match(regex);
    const is_a_phrase = /(?<=\S)\s(?=\S)/.test(word.word);
    if (got_punctuation && !is_a_phrase) {
      word.is_not_a_word = true;
      word.known = undefined;
      // NOTE: this works for making multiline selection possible on mobile but it also puts spaces in the beginning of new lines sometimes
      // if (word.word === " ") word.word = "\u00A0";
    } else {
      const known = checkIfWordIsKnown(word.word, knownWords);
      if (known) {
        word.known = true;
        word._id = known._id;
        word.translation = known.translation;
        word.strength = known.strength;
        word.srs_due_date = known.srs_due_date;
      }
    }
    const regexNumber = /[\d]+([.,\s]?[\d]+)*/g; // includes also separators
    const is_number = word.word.match(regexNumber);
    if (is_number) {
      word.is_number = true;
    }
    words.push(word);
  }
  return words;
}

export default App;
