import React, { useState, useContext } from "react";
import { Card, Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BadgeCc } from "react-bootstrap-icons";
import PlaceholderImg from "../chalice-150x150.png";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../context/AuthContext";

import "./VideoCard.css";

export default function VideoCard({ video, index }) {
  const [showModal, setShowModal] = useState(false);
  const [captionsProcessing, setCaptionsProcessing] = useState(false);
  const [captionsSuccessMessage, setCaptionsSuccessMessage] = useState(false);

  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const openVideoPlayer = (videoId) => () => {
    setShowModal(true);
    trackVideoWatched(videoId, "webapp");
  };

  const closeVideoPlayer = () => {
    setShowModal(false);
  };

  const importSubtitles = async (
    e,
    videoId,
    location,
    language_learning_code,
    language_learning_name,
    image_url,
    channel_title,
    channel_id,
    published_at
  ) => {
    e.preventDefault();
    setCaptionsProcessing(true);
    try {
      const response = await axios.post(
        `/api/videos/${videoId}/subtitles/import`,
        {
          location: location,
          language_learning_code: language_learning_code,
          language_learning_name: language_learning_name,
          video_thumbnail_url: image_url,
          channel_title: channel_title,
          channel_id: channel_id,
          published_at: published_at,
          source: "webapp_videos_captions_import"
        }
      );
      // alert("Captions imported successfully.");
      setCaptionsSuccessMessage(true);
      setCaptionsProcessing(false);

      console.log(response.data.message);
    } catch (error) {
      setCaptionsProcessing(false);
      console.error("Error importing video subtitles:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(error.response.data.error);
      } else {
        // Fallback to a general error message if the error details are not available
        alert(error.message);
      }
    }
  };

  return (
    <>
      <Col key={index} xs={12} className="mb-4">
        <Card>
          <Card.Body>
            <Row className="flex-column flex-md-row">
              <Col xs={12} md={2}>
                <div
                  style={{
                    width: "100%",
                    paddingBottom: "56.25%",
                    position: "relative",
                  }}
                  className="mb-3 mb-md-0"
                >
                  <Link onClick={openVideoPlayer(video.videoId)}>
                    <Card.Img
                      variant="top"
                      src={video.thumbnails.default.url || PlaceholderImg}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: video.thumbnails.default.url
                          ? "cover"
                          : "scale-down",
                      }}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite callback loop
                        e.target.src = PlaceholderImg; // Replace with placeholder image
                        e.target.style.objectFit = "scale-down"; // Change objectFit to scale-down
                      }}
                    />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={10}>
                <Card.Title>
                  <Link
                    onClick={openVideoPlayer(video.videoId)}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {video.title}
                  </Link>
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted">
                  {video.channelTitle} |{" "}
                  <span className="text-none">
                    {video.publishedAt.slice(0, 10)}
                  </span>
                </Card.Subtitle>
                <Card.Text>
                  {/* converts to user's local timezone */}
                  {/* {new Date(article.date_imported).toLocaleString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )} */}
                </Card.Text>
                <Button
                  onClick={openVideoPlayer(video.videoId)}
                  variant="outline-dark"
                  className="btn-sm me-2 mt-2"
                >
                  Watch
                </Button>
                <Link
                  to={`https://www.youtube.com/watch?v=${video.videoId}`}
                  onClick={() => {
                    trackVideoWatched(video.videoId, "webapp");
                  }}
                  target="_blank"
                >
                  <Button
                    // href={`/reader/${article._id}`}
                    variant="outline-dark"
                    className="btn-sm me-2 mt-2"
                  >
                    Watch on YouTube
                  </Button>
                </Link>
                <Button
                  onClick={(e) =>
                    importSubtitles(
                      e,
                      video.videoId,
                      "webapp",
                      context.getSelectedLanguagePair().language_learning.code,
                      context.getSelectedLanguagePair().language_learning.name,
                      video.thumbnails.default.url,
                      video.channelTitle,
                      video.channelId,
                      video.publishedAt
                    )
                  }
                  disabled={captionsProcessing}
                  variant="outline-dark"
                  className="btn-sm me-2 mt-2"
                >
                  {captionsProcessing ? (
                    <>
                      <Spinner animation="grow" size="sm" className="me-2" />{" "}
                      Importing and formatting with AI...
                    </>
                  ) : (
                    <>
                      <BadgeCc className="me-2" /> Import captions for studying
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Modal show={showModal} onHide={closeVideoPlayer} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{video.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-responsive">
            <YouTube videoId={video.videoId} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={captionsSuccessMessage}
        centered={true}
        onHide={() => {
          setCaptionsSuccessMessage(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <BadgeCc className="me-2 mb-1" />
            YouTube captions import is complete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You can find the captions under "Imported content" in your Library.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              navigate("/library?tab=imported");
              setCaptionsSuccessMessage(false);
            }}
          >
            Show it in Library &#8594;
          </Button>
          {/* <Button
            variant="success"
            onClick={() => {
              navigate("/reader/" + simplificationMessage);
            }}
          >
            Open the new article
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

const trackVideoWatched = async (videoId, location) => {
  try {
    const response = await axios.post(`/api/videos/${videoId}`, {
      location: location,
    });

    console.log(response.data.message);
  } catch (error) {
    console.error("Error tracking video watch:", error);
  }
};
