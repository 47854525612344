import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LevelCurrent from "../Components/LevelCurrent";
import AuthContext from "../context/AuthContext";
import chalice from "../chalice-150x150.png";
import Image from "react-bootstrap/Image";

export default function ReaderFinishedPage({
  knownWordsAtTheStart,
  knownWordsArray,
  currentArticle,
  articleId,
  audioUrl,
  generateAudioIsProcessing,
  setGenerateAudioIsProcessing,
  setAudioUrl,
  showPlaylistSelectionModal,
  setShowPlaylistSelectionModal,
}) {
  const context = React.useContext(AuthContext);
  const [showStreak, setShowStreak] = useState(true);

  // const today = new Date().toISOString().split("T")[0];
  const [hasReadToday, setHasReadToday] = useState(!context.statistics?.missedCurrentDay || false);
  const [newStreakCount, setNewStreakCount] = useState(context.statistics?.streakCount || 0);
  const [showNewTrophy, setShowNewTrophy] = useState(false);

  console.log("--- reader finished page starts with streak count: ", newStreakCount);

  useEffect(() => {
    console.log("context.statistics: ", context.statistics);
    // console.log("today: ", today);
    // const checkHasReadToday = context.statistics?.wordsReadByDay?.some(
    //   (day) => day.date === today
    // );
    console.log("hasReadToday: ", hasReadToday);

    if (!hasReadToday) {
      // NOTE: I could fetch stats here but there's no guarantee it'll be updated in time
      // so I'll just add +1 to the streak count after a delay
      setTimeout(() => {
        setNewStreakCount(newStreakCount + 1);
        setShowNewTrophy(true);
      }, 1000);
    }
  }, []);

  if (!hasReadToday && showStreak) {
  // if (true) {
    return (
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <Row className="w-100">
          <Col className="d-flex flex-column align-items-center">
            <div className="text-center mt-5">
              {showNewTrophy ? (
                <Image src={chalice} fluid style={{ height: "100px" }} />
              ) : (
                <Image src={chalice} fluid style={{ height: "100px", filter: "grayscale(100%)" }} />
              )}
              <h1 className="mb-3 streak-number mt-2">{newStreakCount}</h1>
              <h4 className="pb-5">day streak</h4>
            </div>
            <Button
              variant="primary"
              className="mt-5"
              onClick={() => setShowStreak(false)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="d-flex" style={{ minHeight: "80vh" }}>
      <Row className="m-auto w-100 mb-2">
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <div className="text-center">
            <LevelCurrent
              knownWordsArray={knownWordsArray}
              knownWordsAtTheStart={knownWordsAtTheStart}
              currentArticle={currentArticle}
              audioUrl={audioUrl}
              generateAudioIsProcessing={generateAudioIsProcessing}
              setGenerateAudioIsProcessing={setGenerateAudioIsProcessing}
              articleId={articleId}
              setAudioUrl={setAudioUrl}
              showPlaylistSelectionModal={showPlaylistSelectionModal}
              setShowPlaylistSelectionModal={setShowPlaylistSelectionModal}
            />

            <Link to="/library">
              <Button variant="secondary" className="mx-2 mt-3">
                &larr; Back to Library
              </Button>
            </Link>
            <Button
              variant="warning"
              onClick={() => {
                window.open("https://tally.so/r/wbW5X1", "_blank");
              }}
              className="mx-2 mt-3"
            >
              Send feedback
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
