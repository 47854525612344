import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

import socialImage from "../screenshot-front-2.jpg";

import LearnDuolingoVocabularyMainCTA from "../Components/LearnDuolingoVocabularyMainCTA";
// import HowItWorks from "../Components/HowItWorks";
import Benefits from "../Components/Benefits";
import BackedByScience from "../Components/BackedByScience";
import Customize from "../Components/Customize";
import FounderQuote from "../Components/FounderQuote";
import DownloadExtension from "../Components/DownloadExtension";
import DuolingoUninstallMessage from "../Components/DuolingoUninstallMessage";
import WeDontTrainLinguists from "../Components/WeDontTrainLinguists";
import Explanations from "../Components/Explanations";
import Videos from "../Components/Videos";
import Audio from "../Components/Audio";
import BrowserExtension from "../Components/BrowserExtension";
import LanguageSupport from "../Components/LanguageSupport";
import GenerateContentWithAI from "../Components/GenerateContentWithAI";
import FlashcardsFeatureSection from "../Components/FlashcardsFeatureSection";
import StatisticsSection from "../Components/StatisticsSection";
import UseChat from "../Components/UseChat";
import StudySongs from "../Components/StudySongs";
function Home() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta
          name="description"
          key="description"
          content={metaData.metaDesc}
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "3% 3%" }}>
        <DuolingoUninstallMessage />
        <LearnDuolingoVocabularyMainCTA />
        {/* <MostLanguageLearningAppsAreTheSame /> */}
        {/* <TheSecretSauce /> */}
        <LanguageSupport />
        <WeDontTrainLinguists />
        {/* <HowItWorks /> */}
        <Customize />
        <Explanations />
        <UseChat />
        <Audio />
        <FlashcardsFeatureSection />
        <Videos />
        <StudySongs />
        {/* <Exercises /> */}
        <GenerateContentWithAI />
        <StatisticsSection />
        <Benefits />
        <BrowserExtension />
        <BackedByScience />
        <FounderQuote />
        <DownloadExtension />
      </Container>
    </>
  );
}

export default Home;
