import React from "react";
import { Image, Button } from "react-bootstrap";
import { BrowserChrome } from "react-bootstrap-icons";
import ScreenshotExtension from "../screenshot-extension-1.png";

function BrowserExtension() {
  const imageContainerStyle = {
    position: "relative",
    display: "inline-block", // This makes the container fit the image size
  };

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "5%", marginTop: "10%" }}
      >
        Keep Learning While Browsing The Web
      </h1>

      <h4 className="text-center mb-5">
        Translate parts of the web pages you visit automatically with the Chrome extension
      </h4>

      <div className="d-flex justify-content-center mt-5 mb-4">
        <div style={imageContainerStyle}>
          <Image
            src={ScreenshotExtension}
            fluid
            style={{
              width: "100%",
              maxWidth: "900px",
              border: "1px solid lightgray",
              borderRadius: "10px",
              padding: "2em 4em",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "150px", // Adjust the height to control the fade area
              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <Button variant="primary"><BrowserChrome style={{marginBottom: "3px"}} className="me-1" /> Check out the Chrome Extension</Button>
      </div>

      {/* <CardGroup style={{ marginBottom: "0%" }}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Sync Your Vocabulary With The Lingo Champion Browser Extension
            </Card.Title>
            <Card.Text>
              Install the{" "}
              <a
                href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff?hl=en-GB"
                target="_blank"
              >
                browser extension
              </a>{" "}
              for free from the Chrome Web Store. Customize it to ignore certain
              domains etc.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>
              Translate Words Or Sentences Automatically On The Websites You
              Visit
            </Card.Title>
            <Card.Text>
              Translate parts of web pages automatically. Choose between
              translating words or sentences. These translations are based on
              your existing vocabulary.
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup> */}
    </div>
  );
}

export default BrowserExtension;
