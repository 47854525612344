import React from "react";
import LearnDuolingoVocabularyMainCTA from "../../Components/LearnDuolingoVocabularyMainCTA";
import Benefits from "../../Components/Benefits";
import BackedByScience from "../../Components/BackedByScience";
import Customize from "../../Components/Customize";
import FounderQuote from "../../Components/FounderQuote";
import DownloadExtension from "../../Components/DownloadExtension";
import BuiltForComprehensibleInput from "./BuiltForComprehensibleInput";
import WeDontTrainLinguists from "../../Components/WeDontTrainLinguists";
import Explanations from "../../Components/Explanations";
import Audio from "../../Components/Audio";
import FlashcardsFeatureSection from "../../Components/FlashcardsFeatureSection";
import Videos from "../../Components/Videos";
import GenerateContentWithAI from "../../Components/GenerateContentWithAI";
import StatisticsSection from "../../Components/StatisticsSection";
import BrowserExtension from "../../Components/BrowserExtension";
import UseChat from "../../Components/UseChat";
import StudySongs from "../../Components/StudySongs";
export default function ComprehensibleInputImports({ language }) {
  return (
    <>
      <LearnDuolingoVocabularyMainCTA language={language} />
      {/* <HowItWorks /> */}
      <WeDontTrainLinguists />
      <Customize />
      <BuiltForComprehensibleInput />
      <BackedByScience />
      <Explanations />
      <UseChat />
      <Audio />
      <FlashcardsFeatureSection />
      <Videos />
      <StudySongs />
      <GenerateContentWithAI />
      <StatisticsSection />
      <Benefits />
      <BrowserExtension />
      <FounderQuote language={language} />
      <DownloadExtension language={language} />
    </>
  );
}
