import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import { Robot, MusicNoteList } from 'react-bootstrap-icons'; // Adjust based on how you import icons

function GeneratingAudioModal({ articleId, show, setShow, generateAudioIsProcessing, setGenerateAudioIsProcessing, setShowPlaylistSelectionModal, setAudioUrl }) {
  const [error, setError] = useState(null);
  const [generateAudioMessage, setGenerateAudioMessage] = useState(false);
  const [generateAudioError, setGenerateAudioError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (generateAudioIsProcessing && articleId) {
      generateAudio();
    }
  }, [generateAudioIsProcessing, articleId]);

  async function generateAudio() {
    setError(null);

    try {
      const response = await axios({
        method: 'post',
        url: `/api/text-to-speech/articles/${articleId}`,
      });
      // this is necessary for the reader - otherwise the whole article will be re-processed
      setAudioUrl(response.data.audioUrl);
      setGenerateAudioMessage(true);
    } catch (error) {
      setError(error.message);
      setGenerateAudioError(true);
      console.error('Error getting TTS for article:', error);
    } finally {
      setGenerateAudioIsProcessing(false);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Audio is being generated...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p><strong>This might take more than a few seconds.</strong> You'll get a notification once it's complete.</p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={generateAudioMessage}
        centered
        onHide={() => setGenerateAudioMessage(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Robot className="me-2 mb-1" />
            Audio generation is complete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>You can now listen to the article.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setGenerateAudioMessage(false);
              setShow(false);
              setShowPlaylistSelectionModal(true);
            }}
            className="d-flex align-items-center"
          >
            <MusicNoteList className="me-2" />Add article to playlist
          </Button>
          <Button
            variant="success"
            onClick={() => {
              navigate("/player/" + articleId);
            }}
          >
            Listen to the article &#8594;
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={generateAudioError}
        centered
        onHide={() => setGenerateAudioError(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Robot className="me-2 mb-1" color="red" />
            Error generating audio
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This feature is still in beta, so, errors happen sometimes. Please
            try again or contact support if this error persists.
          </p>
          <p>
            The message from the server was: <br />
            <i>{error}</i>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setGenerateAudioError(false)}
          >
            Okay, whatever
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { GeneratingAudioModal };