import React from "react";
import { Image } from "react-bootstrap";
import ScreenshotAudio from "../screenshot-audio-1.jpg";

function Audio() {
  const imageContainerStyle = {
    position: "relative",
    display: "inline-block", // This makes the container fit the image size
  };

  return (
    <div>
      <h1
        className="text-center"
        style={{ marginBottom: "5%", marginTop: "10%" }}
      >
        Listen Back To The Texts You're Studying
      </h1>
      <h4 className="text-center pb-4">
        Follow along with automatic scrolling and text highlighting
      </h4>

      <div className="d-flex justify-content-center mt-5 mb-4">
        <div style={imageContainerStyle}>
          <Image
            src={ScreenshotAudio}
            fluid
            style={{
              width: "100%",
              maxWidth: "900px",
              border: "1px solid lightgray",
              borderRadius: "10px",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "150px", // Adjust the height to control the fade area
              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Audio;
