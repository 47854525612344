import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

import socialImage from "../lingo-champion-social-sharing.png";

function ImportDuolingoVocabulary() {
  const metaData = React.useContext(AppMetaDataContext);
  return (
    <>
      <Helmet>
        <title>Import Duolingo Vocabulary List - {metaData.appName}</title>
        <meta
          name="description"
          key="description"
          content="How to find and import your Duolingo vocabulary list to Lingo Champion."
        />
        <meta property="og:image" key="og:image" content={socialImage} />
        <meta
          property="og:title"
          key="og:title"
          content={metaData.metaTitleSocial}
        />
        <meta
          property="og:description"
          key="og:description"
          content={metaData.metaDescSocial}
        />
      </Helmet>

      <Container style={{ padding: "1% 5%" }}>
        <h1 className="mt-4">
          Import Your Duolingo Vocabulary List to Lingo Champion
        </h1>

        <div className="mt-4">
          <p></p>
        </div>

        <div className="mt-4">
          <h2>How to find your Duolingo vocabulary</h2>
          <p>
            Duolingo used to have a vocabulary list right inside their app. This has
            now been removed, so, the only way to get your vocabulary is to use
            third party apps.
          </p>
          <p>
            Use the{" "}
            <a href="https://duolingoninja.com" target="_blank">
              Duolingo Ninja browser extension
            </a>{" "}
            to find your <a href="https://duolingoninja.com/duolingo-vocabulary-list" target="_blank">Duolingo vocabulary list</a>. It has both free and paid
            plans. The output contains the words you have learned, their
            translations, and some other meta info, such as part of speech, date
            last practiced and word strength.
          </p>
        </div>

        <div className="mt-4">
          <h2>How to import the words to Lingo Champion</h2>
          <p>
            The import option will be available soon. You will be able to copy-paste your Duolingo vocabulary list to Lingo Champion.
          </p>
        </div>

      </Container>

      <Container style={{ padding: "3% 3%" }}>
        {/* <LearnDuolingoVocabularyMainCTA />
        <HowItWorks />
        <Customize />
        <Benefits />
        <FounderQuote />
        <DownloadExtension /> */}
      </Container>
    </>
  );
}

export default ImportDuolingoVocabulary;
