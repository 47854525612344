import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { Trash, GripVertical } from "react-bootstrap-icons";
import "./CollectionEditor.css";

const CollectionEditor = () => {
  const { id: collectionId } = useParams();
  const [items, setItems] = useState([]);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [collectionType, setCollectionType] = useState("book"); // State for collection type
  const [privacySetting, setPrivacySetting] = useState("public"); // State for privacy setting
  const [authorName, setAuthorName] = useState(""); // State for author name
  const [coverImage, setCoverImage] = useState("");
  const [isUploading, setIsUploading] = useState(false); // State to track upload status
  const [isSaving, setIsSaving] = useState(false); // Add state to track saving status

  const fetchCollection = async () => {
    try {
      const response = await axios.get(`/api/collections/${collectionId}`);
      const collection = response.data;
      setCollectionTitle(collection.title);
      setCollectionDescription(collection.description || "");
      setAuthorName(collection.author || "");
      setCoverImage(collection.cover_image || "");
      setPrivacySetting(collection.privacy || "public"); // Default to "public" if not set
      setCollectionType(collection.type || "book"); // Default to "book" if not set

      const sortedArticles = collection.articles
        .sort((a, b) => a.order - b.order)
        .map((article) => ({
          id: article.article_id,
          title: article.title,
          order: article.order,
        }));

      setItems(sortedArticles);
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };

  useEffect(() => {
    fetchCollection();
  }, [collectionId]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedItems = Array.from(items);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(updatedItems);

    const updatedOrder = updatedItems.map((item, index) => ({
      id: item.id,
      order: index + 1,
    }));

    try {
      await axios.put(`/api/user/collection/${collectionId}/reorder`, {
        items: updatedOrder,
      });
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/user/collection/${collectionId}/item/${id}`);
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleSave = async () => {
    setIsSaving(true); // Set saving state to true
    try {
      await axios.put(`/api/user/collection/${collectionId}`, {
        title: collectionTitle,
        description: collectionDescription,
        type: collectionType,
        privacy: privacySetting,
        author: authorName, // Include author in the save request
      });
      console.log("Collection updated successfully");
    } catch (error) {
      console.error("Error updating collection:", error);
    } finally {
      setIsSaving(false); // Reset saving state to false
    }
  };

  const handleDeleteCollection = async () => {
    try {
      await axios.delete(`/api/user/collection/${collectionId}`);
      console.log("Collection deleted successfully");
      // Optionally, redirect the user to another page after deletion
      // e.g., history.push('/collections');
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  const handleImageUpload = async (event) => {
    setIsUploading(true); // Set uploading state to true
    const formData = new FormData();
    formData.append("coverImage", event.target.files[0]);

    try {
      const response = await axios.post(`/api/user/collection/${collectionId}/image-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setCoverImage(response.data.path); // Update cover image path
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false); // Set uploading state to false
    }
  };

  return (
    <div className="container mt-3">
      <div className="row mb-3">
        <h3>Cover Image</h3>
        {coverImage && (
          <div className="col-auto">
            <img
              src={coverImage}
              alt="Cover"
              className="img-thumbnail"
              style={{ maxWidth: "150px", maxHeight: "150px", objectFit: "cover" }}
            />
            <Form.Group controlId="coverImage" className="mt-2">
              <Form.Label>Upload New Cover Image</Form.Label>
              <Form.Control type="file" onChange={handleImageUpload} />
            </Form.Group>
          </div>
        )}
        {isUploading && ( // Conditionally render spinner
          <div className="col-auto mt-2">
            <Spinner animation="border" role="status" />
            <span className="ms-2">Uploading image...</span>
          </div>
        )}
      </div>

      <h3 className="mt-4">Basic Info</h3>
      <Form>
        <Form.Group controlId="collectionTitle">
          <Form.Label><strong>Name</strong></Form.Label>
          <Form.Control
            type="text"
            value={collectionTitle}
            onChange={(e) => setCollectionTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="authorName" className="mt-3">
          <Form.Label><strong>Author Name</strong> (Optional)</Form.Label>
          <Form.Control
            type="text"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
            readOnly // Assuming you want it to be read-only
          />
          <Form.Text className="text-muted">
            Relevant only for books etc.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="collectionDescription" className="mt-3">
          <Form.Label><strong>Description</strong> (Optional)</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={collectionDescription}
            onChange={(e) => setCollectionDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="collectionType" className="mt-3">
          <Form.Label><strong>Collection Type</strong></Form.Label>
          <Row>
            <Col md={4}>
              <Form.Select
                value={collectionType}
                onChange={(e) => setCollectionType(e.target.value)}
              >
                <option value="book">Book</option>
                <option value="course">Course</option>
                <option value="other">Other</option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="privacySetting" className="mt-3">
          <Form.Label><strong>Privacy</strong></Form.Label>
          <Row>
            <Col md={4}>
              <Form.Select
                value={privacySetting}
                onChange={(e) => setPrivacySetting(e.target.value)}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      <Button onClick={handleSave} variant="primary" className="mt-3 me-3" disabled={isSaving}>
        {isSaving ? ( // Conditionally render spinner inside the button
          <>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="ms-2">Saving...</span>
          </>
        ) : (
          "Save Changes"
        )}
      </Button>
      <Button
        onClick={handleDeleteCollection}
        variant="danger"
        className="mt-3 ml-2"
      >
        Delete Collection
      </Button>
      <h2 className="mt-4">Content & ordering</h2>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="collection">
          {(provided) => (
            <Table
              striped
              bordered
              hover
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="mt-3"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map(({ id, title }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>{index + 1}</td>
                        <td>{title}</td>
                        <td>
                          <Trash
                            onClick={() => handleDelete(id)}
                            className="mx-2 remove-item-button"
                          />
                          <span
                            {...provided.dragHandleProps}
                            className="drag-handle"
                          >
                            <GripVertical className="mx-2" />
                          </span>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CollectionEditor;
