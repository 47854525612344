import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Import the plugin
import { Table } from "react-bootstrap"; // Import Bootstrap Table component

const GrammarGuide = ({ language, value, type }) => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    // Dynamically load the Markdown file based on language and tense
    import(`../pages/grammar-guides/${language.toLowerCase()}/${type.toLowerCase()}/${value.toLowerCase()}.md`)
      .then((module) => fetch(module.default))
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text))
      .catch((err) => {
        // If the file doesn't exist, handle the error
        console.error("Error loading markdown file:", err);
        setMarkdownContent("# Guide not available");
      });
  }, [language, value]);

  // Custom renderers for ReactMarkdown to use React Bootstrap components
  const customRenderers = {
    table: ({ children }) => (
      <Table striped bordered hover>
        {children}
      </Table>
    ),
    th: ({ children }) => <th>{children}</th>,
    td: ({ children }) => <td>{children}</td>,
    tr: ({ children }) => <tr>{children}</tr>,
  };

  return (
    <div>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={customRenderers} // Use custom renderers
      >
        {markdownContent}
      </ReactMarkdown>
    </div>
  );
};

export default GrammarGuide;
