import React, { useState, useEffect, useTransition, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import { Tabs, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AppMetaDataContext from "../context/AppMetaDataContext";
import AuthContext from "../context/AuthContext";
import { Helmet } from "react-helmet-async";
import ContinueStudying from "../Components/ContinueStudying";
import axios from "axios";
import OnboardingModal from "../Components/OnboardingModal";
import LibraryCard from "../Components/LibraryCard";
import VideoCard from "../Components/VideoCard";
import SongCard from "../Components/SongCard";
import { useLocation } from "react-router-dom";
import { Magic, ArrowUp, ChatLeft } from "react-bootstrap-icons";
import "./Library.css";
import Playlist from "../Components/Playlist";
import Form from "react-bootstrap/Form";
import BookCard from "../Components/BookCard";
import CollectionCard from "../Components/CollectionCard";
import ChecklistProgress from "../Components/ChecklistProgress";
import { debounce } from "lodash";

export default function Library() {
  const metaData = React.useContext(AppMetaDataContext);
  const context = React.useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  // Initialize states
  const [key, setKey] = useState(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromURL = queryParams.get("tab");

    if (tabFromURL) {
      localStorage.setItem("lastSelectedTab", tabFromURL);
      return tabFromURL;
    }

    return localStorage.getItem("lastSelectedTab") || "news";
  });
  const [articles, setArticles] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [articlesAreLoading, setArticlesAreLoading] = useState(false);
  const [isArticlesLoading, startArticlesTransition] = useTransition();
  const [songs, setSongs] = useState([]);
  const [songsAreLoading, setSongsAreLoading] = useState(false);
  const [currentArticlesPage, setCurrentArticlesPage] = useState(1);
  const [currentStoriesPage, setCurrentStoriesPage] = useState(1);
  const [maxNewWordsPercentage, setMaxNewWordsPercentage] = useState(100);
  const [tempMaxNewWordsPercentage, setTempMaxNewWordsPercentage] =
    useState(100);
  const [importedArticlesAreLoading, setImportedArticlesAreLoading] =
    useState(false);
  const [importedArticles, setImportedArticles] = useState([]);
  const [recentVideosAreLoading, setRecentVideosAreLoading] = useState(false); // Added for YouTube videos
  const [recentVideos, setRecentVideos] = useState([]); // Added for YouTube videos
  const [isStudyArticlesLoading, startStudyArticlesTransition] =
    useTransition();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [books, setBooks] = useState([]); // State to store books
  const [booksAreLoading, setBooksAreLoading] = useState(false); // State to track loading status of books

  const [collections, setCollections] = useState([]); // State to store collections
  const [collectionsAreLoading, setCollectionsAreLoading] = useState(false); // State to track loading status of collections

  // Add state for video categories
  const [selectedVideoCategories, setSelectedVideoCategories] = useState(
    new Set()
  );
  const [uniqueVideoCategories, setUniqueVideoCategories] = useState([]);

  const [showChatText, setShowChatText] = useState(true);

  const [stories, setStories] = useState([]); // State for stories
  const [storiesAreLoading, setStoriesAreLoading] = useState(false); // Loading state for stories
  const [storyCategories, setStoryCategories] = useState([]); // New state for story categories

  // Add state for story categories
  const [selectedStoryCategories, setSelectedStoryCategories] = useState(
    new Set()
  );

  const [maxNewWordsPercentageForStories, setMaxNewWordsPercentageForStories] =
    useState(100);
  const [
    tempMaxNewWordsPercentageForStories,
    setTempMaxNewWordsPercentageForStories,
  ] = useState(100);

  const languageLearningCode =
    context.getSelectedLanguagePair().language_learning.code;

  // Modify the categories state to handle both types
  const [categories, setCategories] = useState({
    news: [],
    stories: []
  });

  // Add new function to fetch all categories at once
  async function getAllCategories() {
    try {
      const response = await axios.get("/api/categories", {
        params: {
          language: context.getSelectedLanguagePair().language_learning.code,
        }
      });
      const allCategories = response.data.categories;
      console.log("Got all categories from the backend: ", allCategories);

      // Filter news categories - exclude Biographies and Fiction
      const newsCategories = allCategories
        .filter(category => !["Biographies", "Fiction"].includes(category.name))
        .map(category => ({
          id: category._id,
          name: category.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      // Story categories - include all categories
      const storyCategories = allCategories
        .map(category => ({
          id: category._id,
          name: category.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setCategories({
        news: newsCategories,
        stories: storyCategories
      });

      // Set initial filters based on user's interests
      if (context.settings?.interest_categories?.length > 0) {
        const userInterestIds = new Set(context.settings.interest_categories);
        
        // Set news categories filter
        setSelectedCategories(userInterestIds);
        
        // Set story categories filter
        setSelectedStoryCategories(userInterestIds);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  // Fetch categories once when the component mounts
  useEffect(() => {
    getAllCategories();
  }, [context.getSelectedLanguagePair().language_learning.code]); // Re-fetch when language changes

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChatText(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleTabSelect = (k) => {
    setKey(k);
    localStorage.setItem("lastSelectedTab", k);
  };

  useEffect(() => {
    // Parse the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    // Check if the 'tab' query parameter is set to 'imported'
    if (tab === "imported") {
      setKey("imported"); // Update the state to set the tab key to 'imported'
    } else if (tab === "playlists") {
      setKey("playlists");
    } else if (tab === "collections") {
      setKey("collections");
    } else if (tab === "videos") {
      setKey("videos");
    } else if (tab === "songs") {
      setKey("songs");
    }
  }, [location]);

  // Set states with imported articles data
  useEffect(() => {
    startArticlesTransition(() => getArticles());
    startStudyArticlesTransition(() => context.fetchStudyArticles());
  }, []);

  //useeffect for location
  useEffect(() => {
    console.log(
      "location changed, so, should start fetching new known words",
      window.location.href
    );
    context.fetchKnownWords();
  }, [window.location.href]);

  // useEffect for show_translated_news_article_titles
  useEffect(() => {
    console.log(
      "show_translated_news_article_titles changed to: ",
      context.settings.show_translated_news_article_titles
    );
  }, [context.settings.show_translated_news_article_titles]);

  // useEffect for categories
  useEffect(() => {
    const uniqueCategoriesSet = new Set();
    articles.forEach((article) => {
      article.categories.forEach((category) => {
        if (!category.includes("dmoz")) {
          uniqueCategoriesSet.add(category);
        }
      });
    });
    setUniqueCategories(Array.from(uniqueCategoriesSet).sort());
  }, [articles]);

  async function getStories() {
    setStoriesAreLoading(true);

    try {
      const [categoriesResponse, storiesResponse] = await Promise.all([
        axios.get("/api/categories"),
        axios.get("/api/articles", {
          params: {
            language: context.getSelectedLanguagePair().language_learning.code,
            page: currentStoriesPage,
            limit: 30,
            content_type: "story",
          },
        }),
      ]);

      const categories = categoriesResponse.data.categories;
      const storiesData = storiesResponse.data.articles;

      if (Array.isArray(storiesData) && storiesData.length > 0) {
        console.log("Got the categories from the backend: ", categories);
        console.log("Got the stories from the backend: ", storiesData);

        // Store categories as objects with _id and name
        setStoryCategories(
          categories
            .map((category) => ({
              id: category._id,
              name: category.name,
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        );
        setStories((prevStories) => [...prevStories, ...storiesData]);
        setCurrentStoriesPage((prevPage) => prevPage + 1);
      } else {
        console.warn("No new stories received from the backend.");
      }

      setStoriesAreLoading(false);
    } catch (error) {
      console.error(
        "There was a problem with getting the stories from the backend: ",
        error
      );
      setStoriesAreLoading(false);
    }
  }

  async function getArticles() {
    setArticlesAreLoading(true);

    let language_learning;
    if (context.language_pairs) {
      for (const pair of context.language_pairs) {
        console.log("Checking language pair: ", pair);
        if (pair.is_selected) {
          language_learning = pair.language_learning.code;
          console.log("Selected language learning code: ", language_learning);
          break;
        }
      }
    }

    if (!language_learning) {
      console.warn("No language learning code selected.");
      setArticlesAreLoading(false);
      return;
    }

    try {
      console.log("Fetching articles for language: ", language_learning);
      const response = await axios.get("/api/articles", {
        params: {
          language: language_learning,
          page: currentArticlesPage,
          limit: 30,
        },
      });

      console.log("Response from backend: ", response);

      if (response.data && Array.isArray(response.data.articles)) {
        console.log("Articles received: ", response.data.articles.length);
        setArticles((prevArticles) => [
          ...prevArticles,
          ...response.data.articles,
        ]);
        console.log("Added articles to state: ", response.data.articles);
        console.log("Updated articles state: ", articles);
        setCurrentArticlesPage((prevPage) => prevPage + 1);
      } else {
        console.warn("No articles array found in response: ", response.data);
      }

      setArticlesAreLoading(false);
    } catch (error) {
      console.error("Error fetching articles: ", error);
      setArticlesAreLoading(false);
    }
  }

  const checkIfArticleIsBookmarked = (articleId) => {
    // check if the article id is in the continue studying list
    return context.study_articles.some((article) => article._id === articleId);
  };

  const checkIfArticleIsOwnedByUser = (article) => {
    // check if the article.import_source.user_id is the same as the user id in context
    return article.import_source.user_id === context.user_id;
  };

  // Update the category toggle handler for news
  const handleNewsCategoryToggle = (categoryId) => {
    console.log("Toggling category:", categoryId);
    const newSelectedCategories = new Set(selectedCategories);
    if (newSelectedCategories.has(categoryId)) {
      console.log("Removing category:", categoryId);
      newSelectedCategories.delete(categoryId);
      // Also remove from story categories
      setSelectedStoryCategories(prev => {
        const newSet = new Set(prev);
        newSet.delete(categoryId);
        return newSet;
      });
    } else {
      console.log("Adding category:", categoryId);
      newSelectedCategories.add(categoryId);
      // Also add to story categories
      setSelectedStoryCategories(prev => {
        const newSet = new Set(prev);
        newSet.add(categoryId);
        return newSet;
      });
    }
    console.log("New selected categories:", newSelectedCategories);
    setSelectedCategories(newSelectedCategories);

    // Update interests with the new combined set
    context.updateInterestCategories(Array.from(newSelectedCategories));
  };

  // Handle checkbox toggle for story category filter
  const handleStoryCategoryToggle = (categoryId) => {
    const newSelectedCategories = new Set(selectedStoryCategories);
    if (newSelectedCategories.has(categoryId)) {
      newSelectedCategories.delete(categoryId);
      // Also remove from news categories
      setSelectedCategories(prev => {
        const newSet = new Set(prev);
        newSet.delete(categoryId);
        return newSet;
      });
    } else {
      newSelectedCategories.add(categoryId);
      // Also add to news categories
      setSelectedCategories(prev => {
        const newSet = new Set(prev);
        newSet.add(categoryId);
        return newSet;
      });
    }
    setSelectedStoryCategories(newSelectedCategories);

    // Update interests with the new combined set
    context.updateInterestCategories(Array.from(newSelectedCategories));
  };

  // Update the article filtering
  const categoryFilteredArticles =
    selectedCategories.size === 0
      ? articles
      : articles.filter((article) => {
          console.log('Article categories:', article.categories);
          console.log('Selected categories:', selectedCategories);
          return article.categories.some((categoryId) => 
            selectedCategories.has(categoryId)
          );
        });

  const filteredArticles = categoryFilteredArticles;

  console.log("Filtered articles: ", filteredArticles);

  function getRecentVideos() {
    setRecentVideosAreLoading(true);
    // send a request to the backend to get the videos
    axios
      .get("/api/recent-videos", {
        params: {
          language: context.getSelectedLanguagePair().language_learning.code,
        },
      })
      .then((res) => {
        console.log("Got the videos from the backend: ", res.data.videos);
        setRecentVideos(res.data.videos);
        setRecentVideosAreLoading(false);
      })
      .catch((error) => {
        console.error("Error getting videos:", error);
        setRecentVideosAreLoading(false);
      });
  }

  function getImportedArticles() {
    setImportedArticlesAreLoading(true);
    // get the current learning language code from context
    let language_learning =
      context.getSelectedLanguagePair().language_learning.code;
    // make axios call to backend
    axios
      .get(`/api/user/articles/imported`, {
        params: {
          language: language_learning, // Replace 'en' with the language you want to filter by
        },
      })
      .then((res) => {
        console.log(
          "Got the imported articles from the backend: ",
          res.data.articles
        );
        setImportedArticles(res.data.articles);

        setImportedArticlesAreLoading(false);
      })
      .catch((error) => {
        console.error("Error getting imported articles:", error);
        setImportedArticlesAreLoading(false);
      });
  }

  function getSongs() {
    setSongsAreLoading(true);
    // get the current learning language code from context
    let language_learning_code =
      context.getSelectedLanguagePair().language_learning.code;
    // make axios call to backend
    axios
      .get(`/api/songs`, {
        params: {
          language: language_learning_code,
        },
      })
      .then((res) => {
        console.log("Got the songs from the backend: ", res.data.songs);
        // let songsWithKnownWords = calculateKnownWordsPerArticle(res.data.songs); // NOTE: can't use this for now bc there are properties which don't exist for songs
        setSongs(res.data.songs);

        setSongsAreLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching songs:", error);
        setSongsAreLoading(false);
      });
  }

  // Function to fetch books
  function getBooks() {
    setBooksAreLoading(true);
    axios
      .get("/api/books", {
        params: {
          language: context.getSelectedLanguagePair().language_learning.code,
        },
      })
      .then((res) => {
        console.log("Got the books from the backend: ", res.data.books);
        setBooks(res.data.books);
        setBooksAreLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching books:", error);
        setBooksAreLoading(false);
      });
  }

  // Function to fetch books
  function getCollections() {
    setCollectionsAreLoading(true);
    axios
      .get("/api/user/collections", {
        params: {
          language: context.getSelectedLanguagePair().language_learning.code,
        },
      })
      .then((res) => {
        console.log(
          "Got the collections from the backend: ",
          res.data.collections
        );
        setCollections(res.data.collections);
        setCollectionsAreLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching collections:", error);
        setCollectionsAreLoading(false);
      });
  }

  useEffect(() => {
    if (key === "imported") {
      console.log("Trying to get imported articles from the backend.");
      getImportedArticles();
    } else if (key === "videos") {
      console.log("Trying to get videos from the backend.");
      getRecentVideos();
    } else if (key === "songs") {
      console.log("Trying to get songs from the backend.");
      getSongs();
    } else if (key === "books") {
      console.log("Trying to get books from the backend.");
      getBooks();
    } else if (key === "collections") {
      console.log("Trying to get collections from the backend.");
      getCollections();
    } else if (key === "stories") {
      console.log("Trying to get stories from the backend.");
      // getStories();
    }
  }, [key]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Update useEffect to extract unique video categories
  useEffect(() => {
    const uniqueCategoriesSet = new Set();
    recentVideos.forEach((video) => {
      uniqueCategoriesSet.add(video.category);
    });
    setUniqueVideoCategories(Array.from(uniqueCategoriesSet).sort());
  }, [recentVideos]);

  // Handle checkbox toggle for video category filter
  const handleVideoCategoryToggle = (category) => {
    const newSelectedCategories = new Set(selectedVideoCategories);
    if (newSelectedCategories.has(category)) {
      newSelectedCategories.delete(category);
    } else {
      newSelectedCategories.add(category);
    }
    setSelectedVideoCategories(newSelectedCategories);
  };

  // Filter videos by selected categories
  const categoryFilteredVideos =
    selectedVideoCategories.size === 0
      ? recentVideos
      : recentVideos.filter((video) =>
          selectedVideoCategories.has(video.category)
        );

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Debounce the update function
  const debouncedSetMaxNewWordsPercentage = useCallback(
    debounce((value) => setMaxNewWordsPercentage(value), 300),
    []
  );

  const handleSliderChange = (e) => {
    const value = Number(e.target.value);
    setTempMaxNewWordsPercentage(value);
    debouncedSetMaxNewWordsPercentage(value);
  };

  // Debounce the update function for stories
  const debouncedSetMaxNewWordsPercentageForStories = useCallback(
    debounce((value) => setMaxNewWordsPercentageForStories(value), 300),
    []
  );

  const handleStorySliderChange = (e) => {
    const value = Number(e.target.value);
    setTempMaxNewWordsPercentageForStories(value);
    debouncedSetMaxNewWordsPercentageForStories(value);
  };

  useEffect(() => {
    if (key === "stories" && stories.length === 0 && !storiesAreLoading) {
      console.log("Fetching stories.");
      getStories();
    }
  }, [key, stories.length]);

  // Filter stories by selected categories
  const categoryFilteredStories =
    selectedStoryCategories.size === 0
      ? stories
      : stories.filter((story) =>
          story.categories.some((category) =>
            selectedStoryCategories.has(category)
          )
        );

  return (
    <Container
      className="d-flex pt-2 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>Library - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div className="mb-2 col-12 col-md-9 col-lg-6">
        <ChecklistProgress studyArticles={context.study_articles} />
      </div>

      {context.study_articles.length > 0 && <ContinueStudying />}

      <Tabs
        id="library-tabs"
        activeKey={key}
        onSelect={handleTabSelect}
        className="mb-3 tabHeading"
      >
        {["es", "pt"].includes(languageLearningCode) && (
          <Tab
            eventKey="stories"
            title={
              <span>
                Stories{" "}
                <sup>
                  <Badge bg="warning">Beta</Badge>
                </sup>
              </span>
            }
          >
            <div className="" style={{ maxWidth: "700px" }}></div>

            {/* Category Filter for Stories */}
            <div className="mb-3 mx-2">
              <h6>Filter by category</h6>
              {storyCategories.map((category, index) => (
                <div className="form-check form-check-inline" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`story-category-${index}`}
                    checked={selectedStoryCategories.has(category.id)}
                    onChange={() => handleStoryCategoryToggle(category.id)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`story-category-${index}`}
                  >
                    {category.name}
                  </label>
                </div>
              ))}
            </div>

            {/* Difficulty Filter for Stories */}
            {context.known_words?.length > 0 && (
              <div className="mx-2 mt-2">
                <h6>Filter by difficulty</h6>
                <div className="d-flex align-items-center mb-3">
                  <label
                    htmlFor="maxNewWordsPercentageForStories"
                    className="form-label me-2"
                  >
                    Max % of new words in a story:{" "}
                    {tempMaxNewWordsPercentageForStories}%
                  </label>
                  <input
                    type="range"
                    className="form-range"
                    id="maxNewWordsPercentageForStories"
                    min="0"
                    max="100"
                    step="10"
                    value={tempMaxNewWordsPercentageForStories}
                    onChange={handleStorySliderChange}
                    style={{ maxWidth: "200px", height: "auto" }}
                  />
                </div>
              </div>
            )}

            {categoryFilteredStories.length === 0 && !storiesAreLoading && (
              <Alert variant="secondary">
                No stories to show. Please adjust the filters or try loading
                more stories.
              </Alert>
            )}

            <Row>
              {categoryFilteredStories.map((story, index) => {
                return (
                  <LibraryCard
                    article={story}
                    index={index}
                    key={index}
                    is_imported={checkIfArticleIsOwnedByUser(story)}
                    is_bookmarked={checkIfArticleIsBookmarked(story._id)}
                    maxNewWordsPercentage={maxNewWordsPercentageForStories}
                  />
                );
              })}
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={getStories}
                  id="loadMore"
                  variant="outline-dark"
                  disabled={storiesAreLoading}
                >
                  {storiesAreLoading ? (
                    <>
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="me-2"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      Loading...
                    </>
                  ) : (
                    "Load more stories"
                  )}
                </Button>
              </Col>
            </Row>
          </Tab>
        )}

        <Tab eventKey="news" title="News feed">
          <div className="" style={{ maxWidth: "700px" }}></div>

          {/* Update the category filter section */}
          <div className="mb-3 mx-2">
            <h6>Filter by category</h6>
            {categories.news.map((category, index) => (
              <div className="form-check form-check-inline" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`news-category-${index}`}
                  checked={selectedCategories.has(category.id)}
                  onChange={() => handleNewsCategoryToggle(category.id)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`news-category-${index}`}
                >
                  {category.name}
                </label>
              </div>
            ))}
          </div>

          {context.known_words?.length > 0 && (
            <div className="mx-2 mt-2">
              <h6>Filter by difficulty</h6>
              <div className="d-flex align-items-center mb-3">
                <label
                  htmlFor="maxNewWordsPercentage"
                  className="form-label me-2"
                >
                  Max % of new words in an article: {tempMaxNewWordsPercentage}%
                </label>
                <input
                  type="range"
                  className="form-range"
                  id="maxNewWordsPercentage"
                  min="0"
                  max="100"
                  step="10"
                  value={tempMaxNewWordsPercentage}
                  onChange={handleSliderChange}
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              </div>
            </div>
          )}

          <div className={`mx-2 ${!context.known_words && "d-none mt-2"}`}>
            <div className="d-flex align-items-center mb-4">
              <Form>
                <Form.Check
                  type="switch"
                  id="translate-titles-switch"
                  checked={context.settings.show_translated_news_article_titles}
                  onChange={() =>
                    context.toggleShowTranslatedNewsArticleTitles()
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              </Form>
              <h6
                htmlFor="showTranslatedNewsArticleTitles"
                className="me-2 mb-0"
              >
                Translate article titles to English
              </h6>
            </div>
          </div>

          {filteredArticles.length === 0 && !articlesAreLoading && (
            <Alert variant="secondary">
              No articles to show. Please adjust the filters or try loading more
              articles.
            </Alert>
          )}

          {!context.known_words?.length === 0 &&
            !articlesAreLoading &&
            maxNewWordsPercentage !== 100 && (
              <Alert variant="warning">
                NB! You don't have any words in your vocabulary yet.{" "}
                <strong>
                  Start reading the articles and we'll add them automatically as
                  you turn the pages.
                </strong>{" "}
                Or you can add them manually during article reading or via
                import.
              </Alert>
            )}

          <Row>
            {filteredArticles.map((article, index) => {
              return (
                <LibraryCard
                  article={article}
                  index={index}
                  key={index}
                  is_imported={checkIfArticleIsOwnedByUser(article)}
                  is_bookmarked={checkIfArticleIsBookmarked(article._id)}
                  maxNewWordsPercentage={maxNewWordsPercentage}
                />
              );
            })}
          </Row>
          <Row>
            <Col>
              {" "}
              {/* Create container with width to maxwidth and justify center content  */}
              {/* <Container className="justify-content-center d-flex"> */}
              <Button
                onClick={getArticles}
                id="loadMore"
                variant="outline-dark"
                disabled={articlesAreLoading}
              >
                {articlesAreLoading ? (
                  <>
                    {" "}
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="me-2"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    Loading...
                  </>
                ) : (
                  "Load more news"
                )}
              </Button>
              {/* </Container> */}
            </Col>
          </Row>
        </Tab>
        {/* tab for Playlist */}
        <Tab eventKey="playlists" title="Playlists">
          <Playlist activeKey={key} />
        </Tab>

        {/* tab for Collections */}
        {context.email.includes("atjackiejohns2") && (
          <Tab
            eventKey="collections"
            title={
              <span>
                Collections{" "}
                <sup>
                  <Badge bg="warning">Beta</Badge>
                </sup>
              </span>
            }
          >
            {context.email.includes("atjackiejohns") && (
              <Link to="/library/import-book">
                <Button
                  size="xl"
                  className="mt-2 me-2 d-flex-inline align-items-center"
                >
                  &#43; Create collection
                </Button>
              </Link>
            )}
            {!collectionsAreLoading && collections.length === 0 && (
              <Alert variant="secondary" className="mt-4">
                &#128566; No collections to show yet.
              </Alert>
            )}
            {collectionsAreLoading && (
              <Alert variant="light" className="mt-4">
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="me-2"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  Loading collections...
                </>
              </Alert>
            )}
            <Row className="mb-5 mt-4">
              {collections.map((collection, index) => (
                <CollectionCard
                  collection={collection}
                  index={index}
                  key={index}
                />
              ))}
            </Row>
          </Tab>
        )}

        {/* tab for Songs/Music */}
        <Tab eventKey="songs" title="Songs">
          <div>
            <Link to="/library/songs/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center"
              >
                &#43; Add song
              </Button>
            </Link>

            {/* <Link to="/vocabulary/import">
              <Button
                size="sm"
                className="me-2 d-flex-inline align-items-center"
                variant="outline-primary"
              >
                &#43; Import words
              </Button>
            </Link> */}
          </div>
          {!songsAreLoading && songs.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; Either the server failed or there are no songs for this
              language yet.
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {songs.map((song, index) => {
              return (
                <SongCard
                  song={song}
                  index={index}
                  key={index}
                  is_imported={checkIfArticleIsOwnedByUser(song)}
                  is_bookmarked={checkIfArticleIsBookmarked(song._id)}
                />
              );
            })}
          </Row>
        </Tab>

        <Tab eventKey="videos" title="Videos">
          {/* Video Category Filter */}
          <div className="mb-3 mx-2">
            <h6>Filter by category</h6>
            {uniqueVideoCategories.map((category, index) => (
              <div className="form-check form-check-inline" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`video-category-${index}`}
                  checked={selectedVideoCategories.has(category)}
                  onChange={() => handleVideoCategoryToggle(category)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`video-category-${index}`}
                >
                  {capitalizeFirstLetter(category)}
                </label>
              </div>
            ))}
          </div>

          {!recentVideosAreLoading && categoryFilteredVideos.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; No videos to show yet. Please contact support if this
              problem persists.
            </Alert>
          )}

          {recentVideosAreLoading && (
            <>
              <Spinner
                animation="border"
                role="status"
                size="sm"
                className="me-2"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              Loading videos...
            </>
          )}

          <Row className="mb-5 mt-4">
            {categoryFilteredVideos.map((video, index) => {
              return <VideoCard video={video} index={index} key={index} />;
            })}
          </Row>
        </Tab>

        {context.email.includes("atjackiejohns2") && (
          <Tab eventKey="books" title="Books">
            {context.email.includes("atjackiejohns") && (
              <Link to="/library/import-book">
                <Button
                  size="xl"
                  className="mt-2 me-2 d-flex-inline align-items-center"
                >
                  &#43; Import book
                </Button>
              </Link>
            )}
            {!booksAreLoading && books.length === 0 && (
              <Alert variant="secondary" className="mt-4">
                &#128566; No books to show yet. Please contact support if this
                problem persists.
              </Alert>
            )}
            {booksAreLoading && (
              <Alert variant="light" className="mt-4">
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="me-2"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  Loading books...
                </>
              </Alert>
            )}
            <Row className="mb-5 mt-4">
              {books.map((book, index) => (
                <BookCard book={book} index={index} key={index} />
              ))}
            </Row>
          </Tab>
        )}

        <Tab eventKey="imported" title="Imported">
          <div>
            <Link to="/library/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center"
              >
                &#43; Import content
              </Button>
            </Link>
            <Link to="/library/generator">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                <Magic className="me-1 mb-1" /> Create content with AI
                <sup>
                  <Badge bg="warning" className="ms-2">
                    New!
                  </Badge>
                </sup>
              </Button>
            </Link>
          </div>
          {!importedArticlesAreLoading && importedArticles.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; No imported content yet.
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {importedArticles.map((article, index) => {
              return (
                <LibraryCard
                  article={article}
                  index={index}
                  key={index}
                  is_imported={true}
                  is_bookmarked={checkIfArticleIsBookmarked(article._id)}
                />
              );
            })}
          </Row>
        </Tab>
        <Tab title={<OnboardingModal />}>&nbsp;</Tab>
      </Tabs>

      {showScrollToTop && (
        <Button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "80px", // Position above the chat button
            right: "20px", // Keep on the right side
            borderRadius: "50%", // Make it circular
            zIndex: 1000,
            backgroundColor: "lightblue",
            borderColor: "lightblue",
            color: "black",
            width: "50px", // Fixed width
            height: "50px", // Fixed height
            padding: "0.8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowUp />
        </Button>
      )}

      {/* Chat Button */}
      <Button
        onClick={() => navigate("/chat")}
        className="btn-dark chat-button"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          borderRadius: showChatText ? "20px" : "50%",
          zIndex: 1000,
          color: "white",
          padding: "0.8rem",
          transition: "all 0.5s ease",
          width: showChatText ? "160px" : "50px",
          height: "50px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: `translate(-50%, -50%) ${
              showChatText ? "translateX(-25px)" : "translateX(0)"
            }`,
            opacity: showChatText ? 1 : 0,
            transition: "all 0.5s ease",
            whiteSpace: "nowrap",
            marginRight: "25px",
          }}
        >
          Chat with AI
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: `translate(-50%, -50%) ${
              showChatText ? "translateX(35px)" : "translateX(0)"
            }`,
            transition: "all 0.5s ease",
          }}
        >
          <ChatLeft />
        </div>
      </Button>
    </Container>
  );
}
