import React, { useContext, useEffect } from "react";
import Navigation from "./Components/Navigation";
import { Route, Routes, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./pages/Home";
import Library from "./pages/Library";
import LibraryEditor from "./pages/LibraryEditor";
import LibraryGenerator from "./pages/LibraryGenerator";
import LibraryImportSong from "./pages/LibraryImportSong";
import LibraryImportBook from "./pages/LibraryImportBook";
import CollectionEditor from "./pages/CollectionEditor";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Vocabulary from "./pages/Vocabulary";
import VocabularyImporter from "./pages/VocabularyImporter";
import Statistics from "./pages/Statistics";
import Reader from "./pages/Reader";
// import Reader2 from "./pages/Reader2";
// import Reader3 from "./pages/Reader3";
import Music from "./pages/Music";
import ReaderHeight from "./pages/ReaderHeight";
import Player from "./pages/Player";
import Chat from "./pages/Chat";
import Review from "./pages/Review";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Account from "./pages/Account";
import Privacy from "./pages/Privacy";
import Admin from "./pages/Admin";
import ReleaseNotes from "./pages/ReleaseNotes";
import TermsOfService from "./pages/TermsOfService";
import ComprehensibleInput from "./pages/ComprehensibleInput";
import NotFound from "./pages/NotFound";
import CookieDetection from "./pages/CheckIfCookiesAreEnabled";
import Footer from "./Components/Footer";

import Spanish from "./pages/comprehensible-input/Spanish";
import Estonian from "./pages/comprehensible-input/Estonian";
import French from "./pages/comprehensible-input/French";
import Finnish from "./pages/comprehensible-input/Finnish";
import German from "./pages/comprehensible-input/German";
import Italian from "./pages/comprehensible-input/Italian";
import Norwegian from "./pages/comprehensible-input/Norwegian";
import Danish from "./pages/comprehensible-input/Danish";
import Polish from "./pages/comprehensible-input/Polish";
import Portuguese from "./pages/comprehensible-input/Portuguese";
import Russian from "./pages/comprehensible-input/Russian";
import Latvian from "./pages/comprehensible-input/Latvian";
import Swedish from "./pages/comprehensible-input/Swedish";
import Thai from "./pages/comprehensible-input/Thai";
import Vietnamese from "./pages/comprehensible-input/Vietnamese";

import LanguageCourse from "./pages/LanguageCourse";

import ScrollToTop from "./utils/ScrollToTop";

// could be rewritten and simplified to use a Hook instead
// https://www.youtube.com/watch?v=ZBZ6BqoUDsU&ab_channel=yoursTRULY
import AuthContext from "./context/AuthContext";

import { FullStory } from "@fullstory/browser";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import ImportDuolingoVocabulary from "./pages/ImportDuolingoVocabulary";

import GrammarGuidePage from "./pages/GrammarGuidePage";

function App() {
  const context = useContext(AuthContext);
  console.log("CONTEXT VARIABLES AND METHODS: ", context);

  // send pageview to Google Analytics only when location (path) changes
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-XFLY2RT4FR");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  // Effect for identifying the user with FullStory
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      context.email &&
      context.user_id
    ) {
      FullStory.identify(context.user_id, {
        email: context.email,
        plan: context.plan,
      });
      console.log("FullStory user identified.");
    }
  }, [context.email, context.user_id]);

  useEffect(() => {
    context.getAuthInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (context.has_been_verified === undefined) {
    return <div>Loading...</div>;
  }

  const isReaderRoute = location.pathname.includes("/reader");
  const isReviewRoute = location.pathname.includes("/review");
  const isPlayerRoute = location.pathname.includes("/player");
  const isMusicRoute = location.pathname.includes("/music");
  const isChatRoute = location.pathname.includes("/chat");
  const isRegisterRoute = location.pathname.includes("/register");
  return (
    <>
      {!isReaderRoute &&
        !isReviewRoute &&
        !isMusicRoute &&
        !isPlayerRoute &&
        !isChatRoute && <Navigation />}
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={context.user_id ? <Navigate to="/library" /> : <Home />}
        />
        <Route path="/home" element={<Home />} />
        
        {/* Library routes */}
        <Route 
          path="/library" 
          element={
            context.user_id 
              ? <Library /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/library/editor/:id" 
          element={
            context.user_id 
              ? <LibraryEditor /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/library/editor" 
          element={
            context.user_id 
              ? <LibraryEditor /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/library/collection/editor/:id" 
          element={
            context.user_id 
              ? <CollectionEditor /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/library/generator" 
          element={
            context.user_id 
              ? <LibraryGenerator /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/library/songs/editor" 
          element={
            context.user_id 
              ? <LibraryImportSong /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/library/songs/editor/:id" 
          element={
            context.user_id 
              ? <LibraryImportSong /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/library/import-book" 
          element={
            context.user_id 
              ? <LibraryImportBook /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />

        {/* Vocabulary routes */}
        <Route 
          path="/vocabulary/import" 
          element={
            context.user_id 
              ? <VocabularyImporter /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/vocabulary" 
          element={
            context.user_id 
              ? <Vocabulary /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/vocabulary/review" 
          element={
            context.user_id 
              ? <Review /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/vocabulary/review/:articleId" 
          element={
            context.user_id 
              ? <Review /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />

        {/* Statistics and Account routes */}
        <Route 
          path="/statistics" 
          element={
            context.user_id 
              ? <Statistics /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route 
          path="/account" 
          element={
            context.user_id 
              ? <Account /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />

        {/* Chat route */}
        <Route 
          path="/chat" 
          element={
            context.user_id 
              ? <Chat /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />

        <Route 
          path="/reader/:id" 
          element={
            context.user_id 
              ? <Reader /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route
          path="/reader/books/:bookId/chapters/:chapterId"
          element={
            context.user_id 
              ? <Reader /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          }
        />
        {context.user_id && context.email.includes("atjackiejohns") && (
          <Route path="/admin" element={<Admin />} />
        )}
        {/* <Route path="/reader2/:id" element={<Reader2 />} />
        <Route path="/reader3/:id" element={<Reader3 />} /> */}
        <Route 
          path="/music/:id" 
          element={
            context.user_id 
              ? <Music /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        {context.user_id && context.email.includes("atjackiejohns") && (
          <Route path="/reader-height" element={<ReaderHeight />} />
        )}
        <Route 
          path="/player/:id" 
          element={
            context.user_id 
              ? <Player /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/account/change-password" element={<ChangePassword />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/cookie-detector" element={<CookieDetection />} />

        <Route path="/comprehensible-input" element={<ComprehensibleInput />} />
        <Route path="/spanish-comprehensible-input" element={<Spanish />} />
        <Route path="/estonian-comprehensible-input" element={<Estonian />} />
        <Route path="/finnish-comprehensible-input" element={<Finnish />} />
        <Route path="/french-comprehensible-input" element={<French />} />
        <Route path="/german-comprehensible-input" element={<German />} />
        <Route path="/italian-comprehensible-input" element={<Italian />} />
        <Route path="/norwegian-comprehensible-input" element={<Norwegian />} />
        <Route path="/danish-comprehensible-input" element={<Danish />} />
        <Route path="/polish-comprehensible-input" element={<Polish />} />
        <Route
          path="/portuguese-comprehensible-input"
          element={<Portuguese />}
        />
        <Route path="/russian-comprehensible-input" element={<Russian />} />
        <Route path="/latvian-comprehensible-input" element={<Latvian />} />
        <Route path="/swedish-comprehensible-input" element={<Swedish />} />
        <Route path="/thai-comprehensible-input" element={<Thai />} />
        <Route
          path="/vietnamese-comprehensible-input"
          element={<Vietnamese />}
        />
        <Route
          path="/course/:language-learn-online"
          element={
            context.user_id 
              ? <LanguageCourse /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          }
        />
        <Route
          path="/import-duolingo-vocabulary"
          element={<ImportDuolingoVocabulary />}
        />
        <Route 
          path="/grammar-guides/:language/:category/:guide" 
          element={
            context.user_id 
              ? <GrammarGuidePage /> 
              : <Navigate to={`/login?returnTo=${encodeURIComponent(location.pathname)}`} />
          } 
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {!isReaderRoute &&
        !isReviewRoute &&
        !isPlayerRoute &&
        !isMusicRoute &&
        !isChatRoute &&
        !isRegisterRoute && <Footer />}
    </>
  );
}

export default App;
