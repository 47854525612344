import React, { useEffect, useState } from 'react'

// NOTE: the package react-resize-detector might help as well

export default function ReaderHeight() {
    const [clientHeight, setClientHeight] = useState(0)
    const [divClientHeight, setDivClientHeight] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(0)
    const [divScrollHeight, setDivScrollHeight] = useState(0)
    const [offsetHeight, setOffsetHeight] = useState(0)
    const [divOffsetHeight, setDivOffsetHeight] = useState(0)
    const [innerHeight, setInnerHeight] = useState(0)
    const [divInnerHeight, setDivInnerHeight] = useState(0)
    const [outerHeight, setOuterHeight] = useState(0)
    const [divOuterHeight, setDivOuterHeight] = useState(0)

    window.addEventListener("resize", () => {
        setAllHeights()
    })
    window.addEventListener("scroll", () => {
        setAllHeights()
    })

    useEffect(() => {
        setAllHeights()
    }
        , [])
    
    function setAllHeights() {
        setClientHeight(document.documentElement.clientHeight)
        setScrollHeight(document.documentElement.scrollHeight)
        setOffsetHeight(document.documentElement.offsetHeight)
        setInnerHeight(window.innerHeight)
        setOuterHeight(window.outerHeight)
        setDivClientHeight(document.getElementById('bigDiv').clientHeight)
        setDivScrollHeight(document.getElementById('bigDiv').scrollHeight)
        setDivOffsetHeight(document.getElementById('bigDiv').offsetHeight)
        setDivInnerHeight(document.getElementById('bigDiv').innerHeight)
        setDivOuterHeight(document.getElementById('bigDiv').outerHeight)
    }
    
    function setDivHeight(height) {
        document.getElementById('bigDiv').style.height = height + "px"
        setAllHeights()
    }

    function removeHorizontalScrollbar(width) {
        document.getElementById('bigDiv').style.width = width + "%"
        setAllHeights()
    }

    function removeHeight() {
        document.getElementById('bigDiv').style.height = "auto"
        setAllHeights()
    }


    return (
      <div style={{marginTop: "10px"}}>
      
      <div style={{width: "110%", backgroundColor: "lightgray", borderTop: "50px solid black", marginTop: "15px"}} id='bigDiv'>
              
                    <button onClick={() => setDivHeight(400)}>Set height to 400px</button>
                <button onClick={() => setDivHeight(1000)}>Set height to 1000px</button>
                <button onClick={() => removeHeight()}>Remove height</button>
                    <button onClick={() => removeHorizontalScrollbar(100)}>Remove scroll</button>
                    <button onClick={() => removeHorizontalScrollbar(110)}>Add scroll</button>
          <table style={{width: "800px"}}>
  <thead>
    <tr>
      <th>Property</th>
                            <th>HTML</th>
                            <th>div</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Client Height</td>
                            <td>{clientHeight}</td>
                            <td>{divClientHeight}</td>
                           
      <td style={{fontSize: "0.8em"}}>Height of the visible content of an element in pixels, including padding but not borders, scrollbars, or margins. Refers to viewport height excluding scrollbars when used on <code>document.documentElement</code>.</td>
    </tr>
    <tr>
      <td>Scroll Height</td>
                            <td>{scrollHeight}</td>
                            <td>{divScrollHeight}</td>
      <td style={{fontSize: "0.8em"}}>Total height of the element's content, including content not visible on the screen without scrolling. Usually greater than clientHeight when there's content overflow. Without horizontal scrollbar (if it's present, the height is smaller). Never smaller than offset height (except for a specific element where it doesn't include borders). It always expands to the visual viewport size.</td>
    </tr>
    <tr>
      <td>Offset Height</td>
                            <td>{offsetHeight}</td>
                            <td>{divOffsetHeight}</td>
      <td style={{fontSize: "0.8em"}}>Height of the element including vertical padding and borders, and horizontal scrollbars if rendered, but not the margin. Used for layout calculations. It doesn't expand to the visual viewport size.</td>
    </tr>
    <tr>
      <td>Inner Height</td>
                            <td>{innerHeight}</td>
                            <td>{divInnerHeight}</td>
      <td style={{fontSize: "0.8em"}}>Height of the window's content area. Includes the vertical size of the viewport and potentially vertical scrollbars.</td>
    </tr>
    <tr>
      <td>Outer Height</td>
                            <td>{outerHeight}</td>
                            <td>{divOuterHeight}</td>
      <td style={{fontSize: "0.8em"}}>Total outer height of the browser window, including browser chrome like toolbars and the status bar as well as the window's content area.</td>
    </tr>
  </tbody>
                </table>
               

            </div>
            </div>
      
  )
}
