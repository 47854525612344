import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Table,
  Button,
  Dropdown,
  DropdownButton,
  Alert,
} from "react-bootstrap";
import {
  PlayFill,
  VolumeUpFill,
  Trash,
  GripVertical,
  SkipForwardFill,
  SkipBackwardFill,
} from "react-bootstrap-icons";
import "./Playlist.css";

const Playlist = ({ activeKey }) => {
  const [playlists, setPlaylists] = useState([]);
  const [items, setItems] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const audioRef = useRef(null);

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get(`/api/user/playlists`);
      const fetchedPlaylists = response.data;
      setPlaylists(fetchedPlaylists);

      if (fetchedPlaylists.length > 0) {
        selectPlaylist(fetchedPlaylists[0]);
      }
    } catch (error) {
      console.error("Error fetching playlists:", error);
    }
  };

  const trackPlaylistPlayedEvent = async (playlistId) => {
    try {
      const response = await axios.get(
        `/api/user/playlist/${playlistId}/played`
      );
      console.log("Playlist play event tracked:", response.data);
    } catch (error) {
      console.error("Error tracking playlist play:", error);
    }
  };

  const selectPlaylist = (playlist) => {
    setPlaylistName(playlist.name);
    setPlaylistId(playlist._id);

    const sortedArticles = playlist.articles
      .sort((a, b) => a.order - b.order)
      .map((article) => ({
        id: article._id,
        articleId: article.article.article_id,
        title: article.article.title,
        title_en: article.article.title_en,
        audio_url: article.article.audio_url,
        order: article.order,
      }));

    setItems(sortedArticles);
    setCurrentTrackIndex(null); // Reset current track index when switching playlists
  };

  useEffect(() => {
    if (activeKey === "playlists") {
      console.log("Fetching the playlists.");
      fetchPlaylists();
    }
  }, [activeKey]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedItems = Array.from(items);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(updatedItems);

    if (currentTrackIndex !== null) {
      const newIndex = updatedItems.findIndex(
        (item) => item.id === items[currentTrackIndex].id
      );
      setCurrentTrackIndex(newIndex);
    }

    const updatedOrder = updatedItems.map((item, index) => ({
      id: item.id,
      order: index + 1,
    }));

    try {
      await axios.put(`/api/user/playlist/${playlistId}/reorder`, {
        items: updatedOrder,
      });
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/user/playlist/${playlistId}/item/${id}`);
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleDeletePlaylist = async (id) => {
    try {
      await axios.delete(`/api/user/playlist/${id}`);
      console.log("Deleted playlist with id:", id);

      const updatedPlaylists = playlists.filter(
        (playlist) => playlist._id !== id
      );
      setPlaylists(updatedPlaylists);

      if (playlistId === id) {
        if (updatedPlaylists.length > 0) {
          selectPlaylist(updatedPlaylists[0]);
        } else {
          setPlaylistName("");
          setItems([]);
          setPlaylistId("");
          setCurrentTrackIndex(null);
        }
      }
    } catch (error) {
      console.error("Error deleting playlist:", error);
    }
  };

  const playTrack = (index) => {
    if (items[index] && audioRef.current) {
      audioRef.current.src = items[index].audio_url;
      audioRef.current.play();
      setCurrentTrackIndex(index);
      trackPlaylistPlayedEvent(playlistId);
    }
  };

  const playNextTrack = () => {
    if (currentTrackIndex < items.length - 1) {
      playTrack(currentTrackIndex + 1);
    }
  };

  const playPreviousTrack = () => {
    if (currentTrackIndex > 0) {
      playTrack(currentTrackIndex - 1);
    }
  };

  return (
    <div className="container mt-3">
      {playlists.length > 0 ? (
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex align-items-center justify-content-left mb-3">
            <h2 className="me-2">Current playlist:</h2>
            <DropdownButton
              id="dropdown-basic-button"
              title={playlistName}
              variant="light"
              size="lg"
              className="mx-2"
            >
              {playlists.map((playlist) => (
                <Dropdown.Item
                  key={playlist._id}
                  onClick={() => selectPlaylist(playlist)}
                >
                  {playlist.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <Button
            onClick={() => handleDeletePlaylist(playlistId)}
            variant="danger"
            className="d-flex justify-content-center align-items-center"
          >
            <Trash className="me-2" />
            Delete playlist
          </Button>
        </div>
      ) : (
        <Alert variant="secondary">
          No audio playlists found yet. You can create playlists when adding
          article audio to playlists.
        </Alert>
      )}

      <div className="mb-3">
        <div className="d-flex">
          <Button
            variant="dark"
            onClick={playPreviousTrack}
            className="mx-2 d-flex justify-content-center align-items-center"
          >
            <SkipBackwardFill className="me-2" /> Previous
          </Button>
          <Button
            variant="success"
            onClick={() => playTrack(0)}
            className="mx-2 d-flex justify-content-center align-items-center"
          >
            <PlayFill className="me-2" /> Play
          </Button>
          <Button
            variant="dark"
            onClick={playNextTrack}
            className="mx-2 d-flex justify-content-center align-items-center"
          >
            <SkipForwardFill className="me-2" /> Next
          </Button>
        </div>
      </div>
      <audio
        ref={audioRef}
        controls
        style={{ width: "100%", maxWidth: "600px", margin: "1em auto" }}
        onEnded={playNextTrack}
      >
        Your browser does not support the audio element.
      </audio>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="playlist">
          {(provided) => (
            <Table
              striped
              bordered
              hover
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map(({ id, title }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={
                          index === currentTrackIndex ? "table-warning" : ""
                        }
                      >
                        <td>{index + 1}</td>
                        <td>
                          <span className="me-2">
                            {index === currentTrackIndex ? (
                              <VolumeUpFill />
                            ) : (
                              <PlayFill
                                onClick={() => playTrack(index)}
                                className="play-item-button"
                              />
                            )}
                          </span>
                          {title}
                        </td>
                        <td>
                          <Trash
                            onClick={() => handleDelete(id)}
                            className="mx-2 remove-item-button"
                          />
                          <span
                            {...provided.dragHandleProps}
                            className="drag-handle"
                          >
                            <GripVertical className="mx-2" />
                          </span>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Playlist;
