import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  ProgressBar,
  Row,
  Col,
} from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import axios from "axios";
import AppMetaDataContext from "../context/AppMetaDataContext";
import ReactGA from "react-ga4";

export default function Register() {
  const metaData = React.useContext(AppMetaDataContext);

  const [languages, setLanguages] = useState([]);
  const [speakLang, setSpeakLang] = useState("");
  const [learnLang, setLearnLang] = useState("");
  const [currentLevel, setCurrentLevel] = useState("");
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [speakLanguageInfoMessage, setSpeakLanguageInfoMessage] =
    useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [shouldCheck, setShouldCheck] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const currentLevelRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const newsletterRef = useRef();

  const levels = [
    { id: 1, name: "Newbie (haven't started learning it yet)" },
    { id: 2, name: "Beginner (know some basics)" },
    { id: 3, name: "Intermediate (can understand familiar topics)" },
    { id: 4, name: "Advanced (fluent in most topics)" },
  ];

  // Fetch and sort languages when component mounts
  useEffect(() => {
    axios
      .get("/api/languages")
      .then((response) => {
        const sortedLanguages = response.data.languages.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setLanguages(sortedLanguages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);

  // useEffect for newsletter checkbox
  useEffect(() => {
    axios
      .get("/api/check-ip-for-newsletter")
      .then((response) => {
        // Assuming the endpoint returns true or false
        setShouldCheck(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error with checking the IP for newsletter!",
          error
        );
      });
  }, []);

  // useEffect for speakLang
  useEffect(() => {
    if (speakLang !== "en" && speakLang !== "" && learnLang !== "en") {
      return;
      setSpeakLanguageInfoMessage(
        "Note that English is probably a more accurate base language. But you can switch and add languages at any time after registration as well."
      );
    } else {
      setSpeakLanguageInfoMessage(false);
    }
    if (speakLang === learnLang && speakLang !== "" && learnLang !== "") {
      // setSpeakLanguageInfoMessage(
      //   "Note that you selected the same language for learning and speaking. This is okay, but you will not be able to use the translation feature - only the AI explanations will be available. You can switch and add languages at any time after registration as well."
      // );
      setSpeakLanguageInfoMessage(
        "You selected the same language for learning and speaking. It works but are you sure you want to do that?"
      );
    }
  }, [speakLang, learnLang]);

  useEffect(() => {
    axios
      .get("/api/categories")
      .then((response) => {
        const sortedCategories = response.data.categories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCategories(sortedCategories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (data) => {
    data.preventDefault();
    if (!speakLang || !learnLang) {
      setErrorMessage("Please select the languages you speak and learn.");
      return;
    }

    const body = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      newsletter: newsletterRef.current.checked,
      language_learning: learnLang,
      language_base: speakLang,
      current_level: currentLevel,
      interest_categories: selectedCategories,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    axios
      .post("/api/user/register", body)
      .then((res) => {
        setShouldRedirect(true);
        ReactGA.event({
          category: "setup",
          action: "signed_up",
          label: "signed_up",
        });
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "60vh" }}
    >
      <Row className="justify-content-center w-100">
        <Col xs={12} md={step === 2 ? 10 : 6} lg={step === 2 ? 8 : 4}>
          <Card className="border-0">
            <Card.Body>
              <h2 className="text-center mb-4">Sign up</h2>
              <ProgressBar
                now={(step / 3) * 100}
                variant={
                  step === 1 ? "primary" : step === 2 ? "warning" : "success"
                }
                style={{ width: "50%", margin: "0 auto", height: "5px" }}
              />

              <div className="mb-5"></div>

              <Form onSubmit={handleSubmit}>
                {step === 1 && (
                  <>
                    <Form.Group id="learn-lang" className="mb-4">
                      <Form.Label className="h5">I want to learn</Form.Label>
                      <Form.Select
                        onChange={(e) => setLearnLang(e.target.value)}
                        value={learnLang}
                      >
                        <option value="" disabled>
                          Select language
                        </option>
                        {languages.map((lang) => (
                          <option key={lang._id} value={lang.code}>
                            {lang.name} {lang.code === "th" && "(in beta)"}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group id="current-level" className="mb-4">
                      <Form.Label className="h5">My current level is</Form.Label>
                      <Form.Select
                        ref={currentLevelRef}
                        value={currentLevel}
                        onChange={(e) => setCurrentLevel(e.target.value)}
                      >
                        <option value="" disabled>
                          Select your{" "}
                          {!learnLang
                            ? "language"
                            : languages.find((lang) => lang.code === learnLang)
                                ?.name}{" "}
                          level
                        </option>
                        {levels.map((level) => (
                          <option key={level.id} value={level.id}>
                            {level.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group id="speak-lang" className="mb-4">
                      <Form.Label className="h5">I speak</Form.Label>
                      <Form.Select
                        onChange={(e) => setSpeakLang(e.target.value)}
                        value={speakLang}
                      >
                        <option value="" disabled>
                          Select language
                        </option>
                        {languages.map((lang) => (
                          <option key={lang._id} value={lang.code}>
                            {lang.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <div className="d-flex justify-content-end mt-5">
                      <Button
                        onClick={handleNext}
                        disabled={!learnLang || !currentLevel || !speakLang}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}

                {step === 2 && (
                  <>
                    <Form.Group id="interests" className="mb-4">
                      <Form.Label className="h5 mb-4">
                        Pick at least 3 interests
                      </Form.Label>
                      <Row xs={1} sm={2} md={2} lg={3} className="g-3">
                        {categories.map((category) => (
                          <Col key={category._id}>
                            <Form.Check
                              type="checkbox"
                              id={`category-${category._id}`}
                              label={category.name}
                              checked={selectedCategories.includes(category._id)}
                              onChange={() => {
                                setSelectedCategories((prev) => {
                                  if (prev.includes(category._id)) {
                                    return prev.filter(
                                      (id) => id !== category._id
                                    );
                                  }
                                  return [...prev, category._id];
                                });
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Form.Group>

                    <div className="d-flex justify-content-between mt-5">
                      <Button onClick={handlePrevious}>Previous</Button>
                      <Button
                        onClick={handleNext}
                        disabled={selectedCategories.length < 3}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}

                {step === 3 && (
                  <>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label className="h5">Your email</Form.Label>
                      <Form.Control
                        type="email"
                        required
                        ref={emailRef}
                        className="w-20"
                      />
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label className="h5">Create a password</Form.Label>
                      <Form.Control type="password" required ref={passwordRef} />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        checked={shouldCheck}
                        label="Let me know via email about new features"
                        ref={newsletterRef}
                        onChange={() => setShouldCheck(!shouldCheck)}
                      />
                    </Form.Group>

                    <div className="d-flex justify-content-between mt-5">
                      <Button onClick={handlePrevious}>Previous</Button>
                      <Button type="submit">Sign up</Button>
                    </div>
                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {/* Already have an account? <Link to="/login">Log in</Link> */}
          </div>
          {shouldRedirect && (
            <div>
              <Navigate to="/login?signup=true" />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
